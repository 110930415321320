import type { CrmContactUsRequest } from '~/types/CrmContactUsRequest'
import type { CrmLeadRequest } from '~/types/CrmLeadRequest'
import type { CrmNewsletterSubscribeRequest } from '~/types/CrmNewsletterSubscribeRequest'

export const submitGrantApplication = async (formData: any): Promise<boolean> => {
  const request: CrmLeadRequest = {
    emailaddress1: formData.email,
    jobtitle: formData.jobTitle,
    companyname: formData.organizationalName,
    address1_country: formData.organizationalCountry,
    websiteurl: formData.organizationalURL,
    addi_areasofexpertiseweb: formData.area.join(','),
    addi_howdidyouhearaboutaddi: formData.howDidYouHear,
    addi_howdidyouhearaboutaddiother: formData.howDidYouHearAboutAddiOther,
    description: formData.comments,
    firstname: formData.name,
    lastname: formData.surname,
    addi_websitesource: 381200002
  }

  const { data } = await useFetch('/api/crm/submit-application', {
    body: request,
    method: 'post'
  })

  if (!data.value) {
    return false
  }
  return data.value.success
}

export const submitContact = async (formData: any): Promise<boolean> => {
  const request: CrmContactUsRequest = {
    emailaddress1: formData.email,
    companyname: formData.organizationName,
    address1_country: formData.organizationCountry,
    description: formData.comments,
    firstname: formData.name,
    lastname: formData.surname,
    addi_websitesource: 381200001
  }

  const { data } = await useFetch('/api/crm/contact-us', {
    body: request,
    method: 'post'
  })

  if (!data.value) {
    return false
  }
  return data.value.success
}

export const subscribeToNewsletter = async (formData: any): Promise<boolean> => {
  let request = {
    emailaddress1: formData.email,
    firstname: formData.name,
    lastname: formData.surname,
    addi_websitesource: 381200001
  } as CrmNewsletterSubscribeRequest

  for (let index = 0; index < formData.interestedTopics.length; index++) {
    const topic = formData.interestedTopics[index]
    request[topic] = true
  }

  const { data } = await useFetch('/api/crm/subscribe-newsletter', {
    body: request,
    method: 'post'
  })

  if (!data.value) {
    return false
  }
  return data.value.success
}
