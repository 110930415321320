<template>
  <section class="container container--bleed home-partners" :class="model?.elements?.background?.value[0]?.codename" v-bind:[attributes.componentId]="type?.id">
    <div class="container container--100">

        <h1 class="horizontal-card__title" v-bind:[attributes.elementCodename]="type.elements.title.codename">
          {{ title }}
        </h1>
        <div class="partners__text" v-bind:[attributes.elementCodename]="type.elements.sub_title.codename">
          {{ subTitle }}
        </div>
        <table>
          <tbody>
            <template v-for="i in getRowCount(model)" :key="i">
              <tr>
                <template v-for="(item, index) in getData(model, i)" :key="index">
                  <HomePartnerItem :model="item"></HomePartnerItem>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      
    </div>
  </section>
</template>

<script setup lang="ts">
import type { BannerPartnersModel, PartnerModel } from '~/models'
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.banner___partners;
const pageStore = usePageStore()

const props = defineProps<{ model: BannerPartnersModel; options: any }>()

const title = ref<string>('')
const subTitle = ref<string>('')
title.value = props.model.elements.title.value
subTitle.value = props.model.elements.subTitle.value

function getRowCount(model: BannerPartnersModel) {
  return model?.elements?.partners?.value?.length ?? 0 / props.options.rowLimit ?? 0
}

function getData(model: BannerPartnersModel, rowIndex: number) {
  const itensPerLine = props.options.rowLimit

  let result: PartnerModel[] = []
  const end = rowIndex * itensPerLine
  const values = model.elements.partners.value.slice(
    (rowIndex - 1) * itensPerLine,
    end > model.elements.partners.value.length ? model.elements.partners.value.length : end
  )

  values.forEach((v) => {
    result.push(pageStore.getLinkedItem<PartnerModel>(v))
  })

  return result
}

</script>

<style lang="scss" scoped>
@use '~/assets/scss/home-partners.scss';
</style>
