<template>
    <section class="container container--100" v-bind:[attributes.itemId]="data?.system.id">
        <div class="back-link" v-if="breadCrumb && breadCrumb?.elements?.content && breadCrumb.elements?.path">
            <NuxtLink :to="breadCrumb.elements.path.value" class="back-link__link">
                Back to {{ breadCrumb.elements.content.value }}
            </NuxtLink>
        </div>
        <div class="page-header container">
            <div class="page-header__eyebrow"></div>
            <h1 class="page-header__title" v-bind:[attributes.elementCodename]="type.title.codename">{{
                data.elements.title.value }}</h1>
            <h2 class="page-header__subtitle" v-bind:[attributes.elementCodename]="type.sub_title.codename">{{
                data.elements.subTitle.value }}</h2>
        </div>

        <NewsLettersList v-if="data?.elements?.newsletterList?.value?.length > 0" />


    </section>
    <SubscriptionBanner v-if="data?.elements?.subscription?.value?.length > 0" />
</template>
<script lang="ts" setup>
import { type BreadcrumbModel, type NewsLetterPageModel } from '~/models';
import { contentTypes } from '~/models';

const attributes = GetKontentAttributes();
const type = contentTypes.news_letter_page.elements;
const pageStore = usePageStore();
const data = pageStore.page as NewsLetterPageModel;
const breadCrumb = data?.elements?.breadcrumb?.value?.length > 0 ? pageStore.getLinkedItem<BreadcrumbModel>(data.elements.breadcrumb.value[0]) : undefined;
</script>

<style lang="scss" scoped>
@use "~/assets/scss/back-link.scss";
@use "~/assets/scss/dynamic-list.scss";
@use "~/assets/scss/page-header.scss";

.dynamic-list {
    padding-top: 20px;
}

.dynamic-list__card-title {
    font-size: 18px;
    font-weight: 400;
}
</style>