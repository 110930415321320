<template>
  <div class="container">
    <div>
      <Form class="sc-form sc-form--with-header global-modal__form" @submit="onSubmit" :validation-schema="schema">
        <div class="global-modal__form-row">
          <div class="global-modal__field-group global-modal__field-group--grow">

            <label class="sc-form-item js-sc-form-required">
              <span class="global-modal__label">First Name</span>
              <Field name="name" type="text" class="global-modal__input"></Field>
              <ErrorMessage class="field-validation-error" name="name" />
            </label>
          </div>
          <div class="global-modal__field-group global-modal__field-group--grow">
            <label class="sc-form-item js-sc-form-required">
              <span class="global-modal__label">Last Name</span>
              <Field name="surname" type="text" class="global-modal__input"></Field>
              <ErrorMessage class="field-validation-error" name="surname" />
            </label>
          </div>
        </div>


        <div class="global-modal__form-row">
          <div class="global-modal__field-group global-modal__field-group--grow">
            <label class="sc-form-item js-sc-form-required">
              <span class="global-modal__label">Email Address</span>
              <Field name="email" type="text" class="global-modal__input"></Field>
              <ErrorMessage class="field-validation-error" name="email" />
            </label>
          </div>
        </div>

        <div class="global-modal__form-row">
          <div class="global-modal__field-group global-modal__field-group--grow">
            <label class="sc-form-item js-sc-form-required">
              <span class="global-modal__label">Organization/Affiliation Name</span>
              <Field name="organizationName" type="text" class="global-modal__input"></Field>
              <ErrorMessage class="field-validation-error" name="organizationName" />
            </label>
          </div>
        </div>

        <div class="global-modal__form-row">
          <div class="global-modal__field-group global-modal__field-group--grow">
            <label class="sc-form-item js-sc-form-required">
              <span class="global-modal__label">Organization/Affiliation Country</span>
              <Field name="organizationCountry" type="text" class="global-modal__input">
              </Field>
              <ErrorMessage class="field-validation-error" name="organizationCountry" />
            </label>
          </div>
        </div>
        <div class="global-modal__form-row">
          <div class="global-modal__field-group global-modal__field-group--grow">
            <label class="sc-form-item js-sc-form-required">
              <span class="global-modal__label">Message</span>
              <Field name="message" as="textarea" class="global-modal__input"></Field>
              <ErrorMessage class="field-validation-error" name="message" />
            </label>
          </div>
        </div>
        <div v-if="isFormSent" class="global-modal__form-row">
          <div class="global-modal__field-group global-modal__field-group--grow">
            <div class="global-modal__success-message">
              <h3>Thank you for contacting us. We will get back to you as soon as possible.</h3>
            </div>
          </div>
        </div>
        <div class="sc-form__button-row">
          <button :disabled="disableSubmit" class="global-modal__submit btn btn--primary">Submit</button>
        </div>
      </Form>
    </div>
  </div>
</template>


<script lang="ts" setup>
import type { MailDataRequired } from "@sendgrid/mail";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { submitContact } from '~/utils/post-crm';
const isFormSent = ref(false);
const disableSubmit = ref(false);

const schema = yup.object({
  name: yup.string().required("You must provide your name"),
  surname: yup.string().required("You must provide your last name"),
  email: yup.string().required().email("You must provide a valid email"),
  organizationName: yup.string().required("You must provide a valid Organization/Affiliation Name"),
  organizationCountry: yup.string().required("You must provide a valid Organization/Affiliation Country"),
  message: yup.string(),
  rememberMe: yup.boolean(),
});

function getText(event: any) {
  return `<p>Here's the latest Contact Us submission from the ADDI website: </p>
    <p><b>From:</b> ${event.name} ${event.surname}</p>
    <p><b>Email:</b> ${event.email}</p>
    <p><b>Organization Name:</b> ${event.organizationName}</p>
    <p><b>Organization Country:</b> ${event.organizationalCountry}</p>
    <p><b>Comments:</b> ${event.message}</p>`;
}
const onSubmit = async (event: any) => {
  disableSubmit.value = true;

  const success = await sendEmail({
    to: event.email,
    subject: `ADDI Contact Us - ${event.name} ${event.surname}`,
    html: getText(event)
  } as MailDataRequired)

  const formData = {
    name: event.name,
    surname: event.surname,
    email: event.email,
    organizationName: event.organizationName,
    organizationCountry: event.organizationCountry,
    comments: event.comments
  }

  const crmResult = await submitContact(formData);
  if (success && crmResult) {
    isFormSent.value = true;
  }
  disableSubmit.value = false;

};


</script>

<style lang="scss" scoped>
@use "~/assets/scss/contact-modal.scss";
</style>