<template>
    <div :class="GetClass(model)" v-bind:[attributes.componentId]="type?.id">
        <picture class="container__picture cs-hero__desktop-picture"
            v-bind:[attributes.elementCodename]="type.elements.landing_page_image.codename">
            <source media="(min-width: 910px)" :srcset="model?.elements?.landingPageImage?.value[0]?.url">
            <source media="(min-width: 780px)" :srcset="model?.elements?.landingPageImage?.value[0]?.url">
            <img alt="" :srcset="model?.elements?.landingPageImage?.value[0]?.url">
        </picture>
        <div class="cs-hero__mobile-picture" v-bind:[attributes.elementCodename]="type.elements.mobile_landing_page_image.codename">
            <img alt="" :srcset="model?.elements?.mobileLandingPageImage?.value[0]?.url" disablewebedit="True" />
        </div>
        <div class=" container--100 cs-hero__content-container">
            <div class="container__col cs-hero__content">
                <div class="cs-hero__type">Case Study</div>
                <h1 class="cs-hero__title" v-bind:[attributes.elementCodename]="type.elements.landing_page_title.codename">{{
                    model?.elements?.landingPageTitle?.value }}</h1>
                <NuxtLink v-bind:[attributes.elementCodename]="type.elements.url_slug.codename" class="btn btn--primary"
                    :to="`/case-studies/${model?.elements?.urlSlug?.value}`">Read Case Study
                </NuxtLink>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import type { CaseStudyModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.case_study;
enum FontEnum {
    None,
    Black,
    White,
}

enum PositionEnum {
    None,
    Left,
    Right
}

const FontMap = new Map<string, FontEnum>([
    ['(None)', FontEnum.None],
    ['Black', FontEnum.Black],
    ['White', FontEnum.White],
]);

const PositionMap = new Map<string, PositionEnum>([
    ['(None)', PositionEnum.None],
    ['Left', PositionEnum.Left],
    ['Right', PositionEnum.Right],
]);


const props = defineProps<{ model: CaseStudyModel }>();

function GetClass(model: CaseStudyModel) {

    return `cs-hero container container--has-background 
    ${(FontMap.get(model?.elements?.fontColor?.value[0]?.name) == FontEnum.Black ? 'black-font' : 'white-font')}
    ${(PositionMap.get(model?.elements?.textPosition?.value[0]?.name) == PositionEnum.Left ? 'cs-hero--left' : 'cs-hero--right')}`;
}

</script>
<style lang="scss" scoped>
@use "~/assets/scss/case-study-hero.scss";
</style>