<template>
    <div class="rich-text rich-text__table">
        <RichText :value="tableContent" />
    </div>
</template>
<script lang="ts" setup>
import type { RichTextTableModel } from '~/models/content-types';
const props = defineProps<{ model: RichTextTableModel }>()
const tableContent = props.model.elements.tableContent.value

</script>