<template>
    <section class="container container--bleed " style="">
        <div class="text-banner container container--has-background" :style="getStyle(model)">
            <div class="text-banner__container container container--100">
                <div class="container__col">
                    <NuxtLink :to="url" class="text-banner__link">
                        <h2 class="text-banner__title">{{ model?.elements?.title?.value }}</h2>
                    </NuxtLink>
                </div>
            </div>
        </div>

    </section>

    <!-- <NuxtLink :to="getRefByModel(model)" class="horizontal-card__link"
        v-if="getStyleEnumByModel(model) == StyleTypeEnum.Black">
        <div class="horizontal-card__card" :style="getStyle(model)">
            <div class="horizontal-card__copy">
                <div class="horizontal-card__text">
                    {{ model?.elements?.title?.value }}
                </div>
            </div>
        </div>

    </NuxtLink>
    <NuxtLink :to="getRefByModel(model)" class="horizontal-card__link"
        v-if="getStyleEnumByModel(model) == StyleTypeEnum.White">
        <div class="horizontal-card__card" :style="getStyle(model)">
            <div class="horizontal-card__copy">
                <div class="horizontal-card__text">
                    {{ model?.elements?.title?.value }}
                </div>
            </div>
        </div>

    </NuxtLink> -->
</template>
<script lang="ts" setup>

import type { BannerLinkModel, TileModel } from '~/models';
const props = defineProps<{ model: BannerLinkModel }>();
const siteStore = useSiteStore()
const url = ref<string>('');
url.value = getRefByModel(props.model);

enum StyleTypeEnum {
    None,
    Black,
    White,
}

function getRefByModel(model: BannerLinkModel) {
    if (model?.elements?.link?.value.length > 0) {
        const codename = model?.elements?.link?.value[0]
        //console.log(codename)
        return siteStore.getUrlByCodename(codename)
    }

    return "/";
}




const styleMaps = new Map<string, StyleTypeEnum>([
    ['(None)', StyleTypeEnum.Black],
    ['Black', StyleTypeEnum.Black],
    ['White', StyleTypeEnum.White],
]);

function getStyle(model: BannerLinkModel) {
    const url = model?.elements?.image?.value[0]?.url ?? '/';
    return `background-image: url(${url}?auto=format)`;
}

</script>
<style lang="scss" scoped>
@use "~/assets/scss/text-banner.scss";

.text-banner.container--has-background {
    background-size: cover;
    background-position: center;
}
</style>