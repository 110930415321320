<template>
    <td class="table-image" v-bind:[attributes.componentId]="type?.id">
        <NuxtLink rel="noopener noreferrer" :to="url" target="_blank">
            <NuxtImg provider="kontentAiProvider" :src="image" />
        </NuxtLink>
    </td>
</template>

<script setup lang="ts">
import { type PartnerModel } from "../models/content-types/partner";
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.partner;
const props = defineProps<{ model: PartnerModel }>();
//console.log('partner', props.model)
const url = ref<string>('')
const image = ref<string>('')
url.value = props.model.elements.url.value
image.value = props.model.elements.logo.value[0].url
</script>

<style lang="scss" scoped>
a {
    border-bottom: none;
}

img {
    width: auto;
    height: auto;
    max-width: 50px;
    max-height: 20px;
    padding-bottom: 20px;

    @media (min-width: 910px) {
        max-width: 150px;
        max-height: 70px;
    }
}
</style>