<template>
    <div class="pull-quote" v-bind:[attributes.componentId]="type?.id">
        <svg class="quote-mark" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="51" height="46"
            viewBox="0 0 51 46">
            <title>Quote Mark</title>
            <text fill-rule="evenodd" font-size="200" transform="translate(-8 -53)">
                <tspan x="0" y="194">“</tspan>
            </text>
        </svg>
        <blockquote class="pull-quote__quote">
            <p v-bind:[attributes.elementCodename]="type.elements.quote.codename">{{ model.elements.quote.value }}</p>
            <div class="pull-quote__caption">
                <span class="pull-quote__author" v-bind:[attributes.elementCodename]="type.elements.author.codename">{{
                    model.elements.author.value }}</span>
                <span class="pull-quote__association" v-bind:[attributes.elementCodename]="type.elements.title.codename">
                    {{ model.elements.title.value }}
                </span>
            </div>
        </blockquote>
    </div>
</template>
<script lang="ts" setup>
import type { CaseStudyQuoteModel } from '~/models';
import type { CaseStudyModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.case_study___quote;

const props = defineProps<{ model: CaseStudyQuoteModel }>();
</script>
<style lang="scss" scoped>
@use "~/assets/scss/pull-quote.scss";
</style>