<template>
    <div class="ad-workbench-page" v-bind:[attributes.itemId]="model?.system.id">
        <section class="container container--100">
            <div class="back-link">
                <NuxtLink to="/ad-workbench" class="back-link__link">
                    Back to
                    About the AD Workbench </NuxtLink>
            </div>
        </section>

        <section class="container container--70-30" >
            <div class="container__col container__col--70">
                <div class="page-header container">
                    <div class="page-header__eyebrow">AD Workbench</div>
                    <h1 class="page-header__title" v-bind:[attributes.elementCodename]="type.title.codename">{{ model.elements.title.value }}</h1>
                </div>
                <RichText :value="model?.elements?.content?.value"></RichText>
            </div>
            <div class="container__col container__col--30 side-content" v-bind:[attributes.elementCodename]="type.sidebar_content.codename">
                <div class="inner-container">
                    <RichText :value="model?.elements?.sidebarContent?.value"></RichText>
                </div>
            </div>
        </section>
    </div>
</template>
<script lang="ts" setup>
import type { AdworkbenchModel, PageModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.adworkbench.elements;
const pageStore = usePageStore()
const model = pageStore.page as AdworkbenchModel

//console.log(model)

</script>
<style lang="scss" scoped >
@use "~/assets/scss/adworkbench.scss";
</style>