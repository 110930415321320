<template>
    <div class="home-page">
        <template v-if="page?.elements.hero?.value.length > 0">
            <PageHero v-for="(codename) in page?.elements.hero?.value"
                :model="pageStore.getLinkedItem<HeroModel>(codename)" />
        </template>
        <RichText :value="page?.elements.content.value" />
    </div>
</template>


<script setup lang="ts">

import { type HeroModel, type BannerContentModel, type N5050BlockModel, type BannerLinksModel, type HomepageModel, type BannerPartnersModel, type BannerQuoteModel } from '~/models';
const pageStore = usePageStore()

const page = pageStore.page as HomepageModel
//console.log('page', page)
</script>

<style lang="scss" scoped>
@use "~/assets/scss/home-page.scss";
</style>
