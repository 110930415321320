import type { MailDataRequired } from '@sendgrid/mail'

export default async function sendEmail(model: MailDataRequired): Promise<boolean> {
  const siteStore = useSiteStore()
  await siteStore.fetchData()
  model.bcc = siteStore.site.elements.bccEmail.value
  model.from = siteStore.site.elements.senderEmail.value
  let adminEmails: string[] = []
  for (const item of siteStore.site.elements.adminEmails.value.split(',')) {
    adminEmails.push(item?.trim())
  }
  model.to = adminEmails
  //console.log(model)
  const { data } = await useFetch('/api/sendgrid/sendemail', {
    body: model,
    method: 'post'
  })

  if (!data.value) {
    return false
  }
  return data.value.success
}
