<template>
    <section class="container container--70-30" v-bind:[attributes.componentId]="typeId">
        <div class="container__col container__col-70">
            <div class="back-link" v-if="breadCrumb && breadCrumb.elements.content.value && breadCrumb.elements.path.value">
                <NuxtLink :to="breadCrumb.elements.path.value" class="back-link__link">
                    Back to {{ breadCrumb.elements.content.value }}
                </NuxtLink>
            </div>
            <div class="page-header container">
                <div class="page-header__eyebrow">Newsletter</div>
                <h1 class="page-header__title" v-bind:[attributes.elementCodename]="type.title.codename">{{
                    page.elements.title.value }}</h1>
            </div>
        </div>
    </section>

    <section class="container container--70-30" v-bind:[attributes.itemId]="page?.system.id">
        <div class="container__col container__col--70" v-bind:[attributes.elementCodename]="type.html_file.codename">
            <div v-html="htmlData"></div>
        </div>

        <div class="container__col container__col--30">
            <template v-if="!submitted">
                <h2 class="global-modal__heading">Join our newsletter</h2>
                <Form class="news-letter-form" novalidate @submit="onSubmit" :validation-schema="schema"
                    :initial-values="formValues" v-slot="{ values }">
                    <div class="global-modal__form-row news-letter-form">
                        <div class="global-modal__field-group global-modal__field-group--grow">

                            <label class="sc-form-item js-sc-form-required">
                                <span class="global-modal__label">First Name</span>
                                <Field name="name" type="text" class="global-modal__input" />
                                <ErrorMessage class="field-validation-error" name="name" />
                            </label>
                        </div>
                        <div class="global-modal__field-group global-modal__field-group--grow">
                            <label class="sc-form-item js-sc-form-required">
                                <span class="global-modal__label">Second Name</span>
                                <Field name="surname" type="text" class="global-modal__input" />
                                <ErrorMessage class="field-validation-error" name="surname" />
                            </label>
                        </div>
                    </div>
                    <div class="global-modal__form-row">
                        <div class="global-modal__field-group global-modal__field-group--grow">
                            <label class="sc-form-item js-sc-form-required">
                                <span class="global-modal__label">Email Address</span>
                                <Field name="email" type="text" class="global-modal__input" />
                                <ErrorMessage class="field-validation-error" name="email" />


                            </label>
                            <label class="js-sc-form-required sc-form-item__label sc-form-item__label--checkbox">I am
                                interested
                                in...
                                <br>
                                <ErrorMessage class="field-validation-error" name="interestedTopics" />
                            </label>

                        </div>
                    </div>

                    <div>
                        <div class="page-form__checkbox-column-container" v-for="topic in interestedTopics">
                            <label class="checkbox">
                                <Field name="interestedTopics" type="checkbox" :value="topic.value" />
                                <span class="checkbox__custom">
                                </span>
                                {{ topic.text }}
                            </label>
                        </div>
                    </div>
                    <div class="sc-form__button-row">
                        <button class="global-modal__submit btn btn--primary">Submit</button>
                    </div>
                </Form>
            </template>
            <h2 v-else class="global-modal__heading">
                <div>Thank you for signing up for our newsletter</div>
            </h2>
        </div>
    </section>
</template>
<script lang="ts" setup>
import type { BreadcrumbModel, NewsLetterPageModel, NewsletterItemModel } from '~/models';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import type { MailDataRequired } from '@sendgrid/mail';
import { contentTypes } from '~/models';
import { subscribeToNewsletter } from '~/utils/post-crm';
const attributes = GetKontentAttributes();
const siteStore = useSiteStore();

const interestedTopics = siteStore.interestedTopics.map((topic) => {
    return {
        text: topic.elements.title.value,
        value: topic.elements.crmField.value,
    };
});

const formValues = {
    name: '',
    surname: '',
    email: '',
    interestedTopics: [...interestedTopics.map((topic) => topic.value)],
};

const type = contentTypes._newsletter_item.elements;
const typeId = contentTypes._newsletter_item.id;
const submitted = ref(false);

const schema = yup.object({
    name: yup.string().required("You must provide your name"),
    surname: yup.string().required("You must provide your second name"),
    email: yup.string().required("You must provide a valid email"),
    interestedTopics: yup.array().min(1, "You must select at least one topic")
});

const pageStore = usePageStore();

const page = pageStore.page as NewsletterItemModel;
let htmlData = ref('');
const uri = ref('');
uri.value = page?.elements?.htmlFile?.value[0]?.url;
//console.log(uri.value);
const { data } = await useFetch(uri.value);
htmlData.value = data.value as string;

const breadCrumb = page?.elements?.backToNewsLetters?.value?.length > 0 ? pageStore.getLinkedItem<BreadcrumbModel>(page.elements.backToNewsLetters.value[0]) : undefined;

const onSubmit = async (values: any) => {

    const success = await sendEmail({
        subject: `ADDI News Letter - ${values.name} ${values.surname}`,
        html: getText(values)
    } as MailDataRequired)

    const crmResult = await subscribeToNewsletter(values)

    if (success && crmResult) {
        submitted.value = true;
    }

};


function getText(event: any) {
    let text = `<p>Here's the confirmation data about news letter signin from the ADDI website: </p>
    <p><b>From:</b> ${event.name} ${event.surname}</p>
    <p><b>Email:</b> ${event.email}</p>
    <ul>`
    for (let i = 0; i < event.interestedTopics.length; i++) {
        text += `<li>${interestedTopics.find(x => x.value == event.interestedTopics[i])?.text}</li>`
    }
    text += `</ul>`
    return text
}
</script>

<style lang="scss" scoped>
@use "~/assets/scss/page-header.scss";

.news-letter-form {
    .global-modal__label {
        color: black !important;
    }

}
</style>