<template>
    <div class="team-bios-listing" v-bind:[attributes.componentId]="typeId">
        <div class="rich-text">
            <table>
                <tbody>
                    <tr v-for="member in memberList" :key="member.system.codename" :id="member.system.codename"
                        v-bind:[attributes.itemId]="member?.system.id">
                        <td valign="top" class="img-td">
                            <NuxtImg provider="kontentAiProvider" v-if="member.elements.image.value.length > 0"
                                :src="member.elements.image.value[0].url" :alt="member.elements.name.value" />
                        </td>
                        <td valign="top">
                            <h3><a :name="member.elements.fullDisplayName.value"
                                    :id="member.elements.fullDisplayName.value"></a>{{
                                        member.elements.fullDisplayName.value
                                    }}
                            </h3>
                            <RichText :value="member.elements.bio.value" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts" setup>

import type { TeamMemberListModel, TeamMemberModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.team_member_list.elements;
const typeId = contentTypes.team_member_list.id;
const pageStore = usePageStore()
const props = defineProps<{ model: TeamMemberListModel }>();
const teamMembers = props.model.elements.teamMembers;
const memberList = ref<TeamMemberModel[]>([]);
memberList.value = [...teamMembers.value.map(item => pageStore.getLinkedItem<TeamMemberModel>(item))];

// const memberList = computed(() => {
//     return teamMembers.value.map((item) => {
//         return pageStore.getLinkedItem<TeamMemberModel>(item);
//     });
// });

</script>

<style lang="scss" scoped>
.team-bios-listing {
    td {
        display: block;
        clear: both;

        @media (min-width: 746px) {
            display: table-cell;
            clear: none;
        }
    }

    .img-td {
        width: 285px;

        img {
            height: 315px;
            width: 250px;
            margin-top: 50px;
        }
    }
}
</style>