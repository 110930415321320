<template>
    <section class="container container--bleed content-banner" v-bind:[attributes.componentId]="type?.id">
        <div class="container container--100">
            <div class="rich-text" :class="model?.elements?.style?.value[0]?.codename">
                <h2 v-if="title.length > 0" class="title" v-bind:[attributes.elementCodename]="contentTypes.banner___content.elements.title.codename" >
                    <span v-html="model?.elements?.title?.value"></span>
                </h2>
                <span v-if="!isEmpty(model?.elements?.content?.value)" 
                v-bind:[attributes.elementCodename]="contentTypes.banner___content.elements.content.codename"
                 v-html="model?.elements?.content?.value"></span>
                <p>
                    <CallToAction v-for="(cta) in model?.elements.cta.value"
                        :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                </p>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { isEmpty } from '~/utils/kontent-helper';
import type { BannerContentModel, CallToActionModel } from '../models';
import { contentTypes } from '../models';
const attributes = GetKontentAttributes();

const type = contentTypes.banner___content;
let loaded = false;
const props = defineProps<{ model: BannerContentModel | null }>();
const pageStore = usePageStore()

const title = ref<string>('');
title.value = props.model?.elements.title.value ?? '';


enum StyleTypeEnum {
    None,
    Centered,
    Left,
    Right,
}

enum BackgroundEnum {
    None,
    Gray,
}

const styleMaps = new Map<string, StyleTypeEnum>([
    ['(None)', StyleTypeEnum.None],
    ['Centered', StyleTypeEnum.Centered],
    ['Left', StyleTypeEnum.Left],
    ['Right', StyleTypeEnum.Right],
]);

const backgroundMaps = new Map<string, BackgroundEnum>([
    ['(None)', BackgroundEnum.None],
    ['Gray', BackgroundEnum.Gray],
]);

function getStyleEnumByModel(model: BannerContentModel): StyleTypeEnum {

    if (model.elements.style && model.elements.style.value && model.elements.style.value.length > 0) {

        let style = model.elements.style.value[0].name;
        return styleMaps.get(style) ?? StyleTypeEnum.None;

    }

    return StyleTypeEnum.None;

}

function getContentHtml(model: BannerContentModel) {
    return model.elements.content.value;
}

function getImageAllignmentByModel(model: BannerContentModel): BackgroundEnum {

    if (model.elements.background && model.elements.background.value && model.elements.background.value.length > 0) {

        let style = model.elements.background.value[0].name;
        return backgroundMaps.get(style) ?? BackgroundEnum.None;
    }


    return BackgroundEnum.None;
}

</script>

<style lang="scss" scoped>
@use "~/assets/scss/content-banner.scss";
</style>