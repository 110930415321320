<template>
    <div class="who-we-are__item">
        <NuxtLink v-if="externalLink.length > 0" :to="externalLink"
            :target="externalLink.startsWith('http') ? '_blank' : '_self'" class="who-we-are__link">
            <span class="who-we-are__name">
                {{ member.elements.titleTextTitle.value }}
            </span>
            <span class="who-we-are__association">
                {{ member.elements.titleTextText.value }}
            </span>
        </NuxtLink>
        <div v-else class="who-we-are__committee-member">
            <span class="who-we-are__name">
                {{ member.elements.titleTextTitle.value }}
            </span>
            <span class="who-we-are__association">
                {{ member.elements.titleTextText.value }}
            </span>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { GlobalTechnicalAdvisoryCommitteeMemberModel } from '~/models';

const props = defineProps<{ model: GlobalTechnicalAdvisoryCommitteeMemberModel }>();
const member = props.model;
const externalLink = ref('')
externalLink.value = member.elements.externalLink.value

</script>