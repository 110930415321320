<template>
    <hr />
    <div class="rich-text" v-bind:[attributes.componentId]="type?.id">
        <table>
            <tbody>
                <tr>
                    <td valign="top" style="width: 200px;">
                        <table>
                            <tbody>
                                <tr>
                                    <td v-bind:[attributes.elementCodename]="type.elements.image.codename">
                                        <NuxtImg provider="kontentAiProvider" v-if="data.image.value.length > 0"
                                            :src="data.image.value[0].url" :alt="data.image.value[0].name"
                                            :width="`${data.image.value[0].width}px`"
                                            :height="`${data.image.value[0].height}px`">
                                        </NuxtImg>
                                    </td>
                                </tr>
                                <tr>
                                    <td v-bind:[attributes.elementCodename]="type.elements.name.codename">
                                        <strong>{{ data.name.value
                                        }}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td style="width:35px;"></td>
                    <td valign="top">
                        <div v-bind:[attributes.elementCodename]="type.elements.professional_background.codename">
                            <strong>Professional Background:</strong>
                            <RichText :value="data.professionalBackground.value" />
                        </div>
                        <div v-bind:[attributes.elementCodename]="type.elements.research_focus.codename"><strong>Research
                                Focus:</strong>
                            <RichText :value="data.researchFocus.value" />
                        </div>
                        <div v-bind:[attributes.elementCodename]="type.elements.proposed_research.codename"><strong>Proposed
                                Research:</strong>
                            <RichText :value="data.proposedResearch.value" />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script setup lang="ts">
import type { FellowModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.fellow;

const props = defineProps<{ model: FellowModel }>();
const data = props.model.elements
</script>
<style lang="scss" scoped>
@import "~/assets/scss/_colors.scss";

strong {
    color: $azure;
}
</style>