<template>
    <div class="accordion container" v-bind:[attributes.componentId]="type?.id">
        <div class="accordion__component-title-wrapper">
            <h2 class="accordion__component-title" v-bind:[attributes.elementCodename]="type.elements.title.codename">{{ model.elements.title.value }}</h2>
        </div>
        <div class="js-accordion accordion__item" v-for="(item, index) in accordionItems" :key="index" v-bind:[attributes.itemId]="item?.system.id">
            <button class="js-accordion-link accordion__title title" type="button" aria-expanded="false"
                id="accordion-link-0" aria-controls="accordion-content-0">
                <svg aria-hidden="true" class="accordion__caret-icon" xmlns="http://www.w3.org/2000/svg" width="24"
                    height="15" viewBox="0 0 24 15">
                    <path fill="none" stroke="#00b7cf" stroke-miterlimit="20" stroke-width="4" d="M2 2v0l10 10v0L22 2v0">
                    </path>
                </svg>
                <span class="annotation-selection__inline-wrapper" v-bind:[attributes.elementCodename]="itemType.title.codename" >
                    {{ item.elements.title.value }}
                </span>
            </button>
            <div className="js-accordion-content" v-bind:[attributes.elementCodename]="itemType.content.codename">
                <!-- <div class="accordion-wrapper" :id="`accordion-content-${index}`" :aria-labelledby="`accordion-link-${index}`"> -->
                <div class="js-accordion-content">
                    <div class="rich-text">
                        <RichText :value="item.elements.content.value" />
                    </div>
                </div>
                <!-- </div> -->
            </div>
        </div>

    </div>
</template>
<script setup lang="ts">
import type { AccordionItemModel, AccordionListModel } from '~/models'
import { initAccordions } from '~/public/js/accordion.js'
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.accordion_list;
const itemType = contentTypes.accordion_item.elements;
const props = defineProps<{ model: AccordionListModel }>();
const pageStore = usePageStore()
const items = props.model.elements.accordionItem.value
const accordionItems = ref<AccordionItemModel[]>([])
for (let i = 0; i < items.length; i++) {
    const item = pageStore.getLinkedItem<AccordionItemModel>(items[i])
    accordionItems.value.push(item)
}

onMounted(() => {
    if (process.client) {
        initAccordions()
    }
})

</script>
<style lang="scss" scoped>
@use "~/assets/scss/accordion.scss";
</style>