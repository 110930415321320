<template>
    <section class="container container--100" v-bind:[attributes.itemId]="typeId">

        <section class="component dynamic-list-mount js-dynamic-list">
            <div class="dynamic-list">
                <div class="search__results container container--100">
                    <section class="container manual-content-listing">
                        <ul class="manual-content-listing__items dynamic-list__list">
                            <li class="dynamic-list__item" v-for="(item) in itemList">
                                <div class="dynamic-list__card" tabindex="-1">
                                    <figure class="dynamic-list__card-media">
                                        <NuxtLink :to="siteStore.getUrlByCodename(item.system.codename)"
                                            :title="item.elements.title.value">
                                            <NuxtImg provider="kontentAiProvider"
                                                :src="item.elements.image.value[0].url">
                                            </NuxtImg>
                                        </NuxtLink>
                                    </figure>
                                    <div class="dynamic-list__card-content">
                                        <div class="dynamic-list__card-meta">
                                            <div class="dynamic-list__card-type">NEWSLETTER</div>
                                            <div class="dynamic-list__card-date" v-if="item.elements.date.value">{{
        useDateFormat(item.elements.date.value, 'MMMM YYYY').value.toUpperCase()
    }}
                                            </div>
                                        </div>
                                        <div class="dynamic-list__card-title">
                                            <NuxtLink :to="siteStore.getUrlByCodename(item.system.codename)"
                                                :title="item.elements.title.value">
                                                {{ item.elements.title.value }}
                                            </NuxtLink>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </section>
                </div>
                <div class="search__pagination search__pagination--centered">
                    <div class="search__more">
                        <div class="search-results__load-more"><button class="btn btn--primary btn--black-hover"
                                @click="loadMore" v-if="allowLoadMore" type="button">Load More</button></div>
                    </div>
                </div>
            </div>
        </section>

    </section>
</template>

<script setup lang="ts">
import { QueryService } from '@kontent-ai/delivery-sdk';
import type { NewsLetterPageModel, NewsletterItemModel } from '~/models';
import PagesAlias from '~/utils/page-keys';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.news_letter_page.elements.newsletter_list;
const typeId = contentTypes.news_letter_page.elements.newsletter_list.id;
const itemList: Ref<NewsletterItemModel[]> = ref([]);
const loadIndex = ref(0);
const siteStore = useSiteStore()

const allowLoadMore = ref(true);
await loadMore();

//console.log(pageRoutesMap);
async function loadMore() {

    loadIndex.value++;
    const { data } = await useFetch(`/api/newsletters?page=${loadIndex.value}`);

    if (data) {
        const newsList = (data as any).value?.data?.items as NewsletterItemModel[];

        if (newsList) {

            newsList.forEach(i => {
                itemList.value.push(i);
            });
        }
    }

    verifyIfTheresMoreContent();
}

async function verifyIfTheresMoreContent() {

    const { data } = await useFetch(`/api/newsletters?page=${loadIndex.value + 1}`);

    if (data) {
        const newsList = (data as any)?.value?.data?.items as NewsletterItemModel[];

        if (newsList) {
            allowLoadMore.value = newsList.length > 0;
            return;
        }

        allowLoadMore.value = false;
    }
}



</script>

<style lang="scss" scoped>
@use "~/assets/scss/back-link.scss";
@use "~/assets/scss/dynamic-list.scss";
@use "~/assets/scss/dynamic-card.scss";

.dynamic-list {
    padding: 0px;
}

.dynamic-list__card-title {
    font-size: 18px;
}

.workbench-hero__text-container {
    padding-bottom: 200px;
}
</style>
