<template>
    <section class="container container--bleed" v-bind:[attributes.componentId]="type?.id">
        <div class="container container--100">
            <h1 style="margin-bottom:30px; margin-top:40px; color: #0978e3;" v-bind:[attributes.elementCodename]="type.elements.title.codename">{{ data.title.value }}</h1>
            <FellowItem v-for="(item) in data.fellows.value" :key="item" :model="pageStore.getLinkedItem<FellowModel>(item)" />
            <hr />
        </div>
    </section>
</template>
<script setup lang="ts">
import type { FellowModel, FellowsListModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.fellows_list;

const pageStore = usePageStore()
const props = defineProps<{ model: FellowsListModel }>();
const data = props.model.elements
</script>
<style lang="scss" scoped>
section {
    background-color: #fff;
}

.detail-page .container+.container {
    margin-top: 0px;
}

.promo__title {
    color: #00b7cf;
    font-size: 40px;
    font-weight: bold;
}

.rich-text h1 {
    font-size: 35px;
}

.rich-text h2 {
    color: #00b7cf;
}

.promo__picture img {
    // margin-top: 120px;
    margin-left: 30px;
    width: 80%;
}

.rich-text {
    margin-bottom: 0px;
}

.rich-text {
    max-width: 2500px;
}
</style>