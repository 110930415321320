<template>
    <div class="who-we-are__item">
        <NuxtLink v-if="externalLink.length > 0" :to="externalLink"
            :target="externalLink.startsWith('http') ? '_blank' : '_self'" class="who-we-are__link">
            <span class="who-we-are__name">
                {{ member.elements.memberName.value }}
            </span>
            <span class="who-we-are__association" v-for="codename in member.elements.partner.value">
                {{ getPartnerName(codename) }}
            </span>
        </NuxtLink>
        <div v-else class="who-we-are">
            <span class="who-we-are__name">
                {{ member.elements.memberName.value }}
            </span>
            <span class="who-we-are__association" v-for="codename in member.elements.partner.value">
                {{ getPartnerName(codename) }}
            </span>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { AdvisoryBoardMemberModel, PartnerModel } from '~/models';
const attributes = GetKontentAttributes();
import { contentTypes } from '~/models';
const page = usePageStore();
const props = defineProps<{ model: AdvisoryBoardMemberModel }>();
const member = props.model;
const externalLink = ref('')
externalLink.value = member.elements.listPagePath.value;


function getPartnerName(codename: string) {
    const partner = page.getLinkedItem<PartnerModel>(codename);
    return partner?.elements.name.value;
}
</script>