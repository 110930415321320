<template>
    <section v-if="getStyleEnumByModel(model) == StyleTypeEnum.None" class="container container--bleed style-none"
        style="padding:1rem;">
        <picture class="container__picture" v-bind:[attributes.elementCodename]="type.background_image.codename">
            <NuxtImg provider="kontentAiProvider" alt="Container image"
                v-for="(item) in model?.elements.backgroundImage.value" :src="item.url">
            </NuxtImg>
        </picture>

        <div v-if="getImageAllignmentByModel(model) == ImageAlignmentEnum.None || getImageAllignmentByModel(model) == ImageAlignmentEnum.Left"
            class="promo promo--left container container--50-50">
            <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                <picture class="promo__picture" v-bind:[attributes.elementCodename]="type.image.codename">
                    <NuxtImg provider="kontentAiProvider" v-for="(item, index) in model?.elements.image.value"
                        :src="item.url"></NuxtImg>
                </picture>
            </div>

            <div class="promo__col container__col container__col--50">
                <div class="promo__text">
                    <h2 class="promo__title" v-bind:[attributes.elementCodename]="type.title.codename">{{
                        model?.elements.title.value }}</h2>
                    <div class="rich-text promo__description" v-bind:[attributes.elementCodename]="type.content.codename">
                        <RichText :value="model?.elements.content.value" />
                    </div>
                    <CallToAction v-for="(cta) in model?.elements.cta.value"
                        :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />

                </div>
            </div>

        </div>

        <div v-if="getImageAllignmentByModel(model) == ImageAlignmentEnum.Right"
            class="promo promo--right container container--50-50">

            <div class="promo__col container__col container__col--50">
                <div class="promo__text">
                    <h2 class="promo__title" v-bind:[attributes.elementCodename]="type.title.codename">{{
                        model?.elements.title.value }} </h2>
                    <RichText :value="model?.elements.content.value" class="promo__description" />
                    <CallToAction v-for="(cta) in model?.elements.cta.value"
                        :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                </div>
            </div>
            <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                <picture class="promo__picture" v-bind:[attributes.elementCodename]="type.image.codename">
                    <NuxtImg provider="kontentAiProvider" v-for="(item, index) in model?.elements.image.value"
                        :src="item.url"></NuxtImg>
                </picture>
            </div>
        </div>
    </section>

    <section v-if="getStyleEnumByModel(model) == StyleTypeEnum.ImageFill"
        class="container container--bleed style-image-fill">
        <picture class="container__picture" v-bind:[attributes.elementCodename]="type.background_image.codename"
            v-for="(item, index) in model?.elements.backgroundImage.value" :v-if="item.url">
            <NuxtImg provider="kontentAiProvider" alt="Container image"
                v-for="(item, index) in model?.elements.backgroundImage.value" :src="item.url">
            </NuxtImg>
        </picture>

        <div v-if="getImageAllignmentByModel(model) == ImageAlignmentEnum.None || getImageAllignmentByModel(model) == ImageAlignmentEnum.Left"
            class="promo promo--left container container--50-50">

            <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                <picture class="promo__picture" v-bind:[attributes.elementCodename]="type.image.codename">
                    <NuxtImg provider="kontentAiProvider" v-for="(item, index) in model?.elements.image.value"
                        :src="item.url"></NuxtImg>
                </picture>
            </div>

            <div class="promo__col container__col container__col--50">
                <div class="promo__text">
                    <h2 class="promo__title" v-bind:[attributes.elementCodename]="type.title.codename">{{
                        model?.elements.title.value }}</h2>
                    <div class="rich-text promo__description" v-bind:[attributes.elementCodename]="type.content.codename">
                        <RichText :value="model?.elements.content.value" />
                    </div>
                    <CallToAction v-for="(cta) in model?.elements.cta.value"
                        class="router-link-active router-link-exact-active promo__button btn btn--primary btn--black-hover"
                        :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                </div>
            </div>
        </div>

        <div v-if="getImageAllignmentByModel(model) == ImageAlignmentEnum.Right"
            class="promo promo--left container container--50-50">

            <div class="promo__col container__col container__col--50">
                <div class="promo__text">
                    <h2 class="promo__title" v-bind:[attributes.elementCodename]="type.title.codename">{{
                        model?.elements.title.value }} </h2>
                    <div class="rich-text promo__description" v-bind:[attributes.elementCodename]="type.content.codename">
                        <RichText :value="model?.elements.content.value" />
                    </div>
                    <CallToAction v-for="(cta) in model?.elements.cta.value"
                        class="router-link-active router-link-exact-active promo__button btn btn--primary btn--black-hover"
                        :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                </div>
            </div>

            <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                <picture class="promo__picture" v-bind:[attributes.elementCodename]="type.image.codename">
                    <NuxtImg provider="kontentAiProvider" v-for="(item, index) in model?.elements.image.value"
                        :src="item.url"></NuxtImg>
                </picture>
            </div>
        </div>
    </section>

    <section v-if="getStyleEnumByModel(model) == StyleTypeEnum.ImageWithBorder"
        class="container container--bleed style-image-with-border" style="padding:1rem;">
        <picture class="container__picture" v-bind:[attributes.elementCodename]="type.background_image.codename">
            <NuxtImg provider="kontentAiProvider" alt="Container image"
                v-for="(item, index) in model?.elements.backgroundImage.value" :src="item.url">
            </NuxtImg>
        </picture>

        <div v-if="getImageAllignmentByModel(model) == ImageAlignmentEnum.None || getImageAllignmentByModel(model) == ImageAlignmentEnum.Left"
            class="promo promo--left container container--50-50">

            <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                <picture class="promo__picture" v-bind:[attributes.elementCodename]="type.image.codename">
                    <NuxtImg provider="kontentAiProvider" v-for="(item, index) in model?.elements.image.value"
                        :src="item.url"></NuxtImg>
                </picture>
            </div>

            <div class="promo__col container__col container__col--50">
                <div class="promo__text">
                    <h2 class="promo__title" v-bind:[attributes.elementCodename]="type.title.codename">{{
                        model?.elements.title.value }}</h2>
                    <div class="rich-text promo__description" v-bind:[attributes.elementCodename]="type.content.codename">
                        <RichText :value="model?.elements.content.value" />
                    </div>
                    <CallToAction v-for="(cta) in model?.elements.cta.value"
                        class="router-link-active router-link-exact-active promo__button btn btn--primary btn--black-hover"
                        :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                </div>
            </div>
        </div>

        <div v-if="getImageAllignmentByModel(model) == ImageAlignmentEnum.Right"
            class="promo promo--left container container--50-50">

            <div class="promo__col container__col container__col--50">
                <div class="promo__text">
                    <h2 class="promo__title" v-bind:[attributes.elementCodename]="type.title.codename">{{
                        model?.elements.title.value }} </h2>
                    <div class="rich-text promo__description" v-bind:[attributes.elementCodename]="type.content.codename">
                        <RichText :value="model?.elements.content.value" />
                    </div>
                    <CallToAction v-for="(cta) in model?.elements.cta.value"
                        class="router-link-active router-link-exact-active promo__button btn btn--primary btn--black-hover"
                        :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                </div>
            </div>

            <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                <picture class="promo__picture" v-bind:[attributes.elementCodename]="type.image.codename">
                    <NuxtImg provider="kontentAiProvider" v-for="(item, index) in model?.elements.image.value"
                        :src="item.url"></NuxtImg>
                </picture>
            </div>
        </div>
    </section>

    <section v-if="getStyleEnumByModel(model) == StyleTypeEnum.Transparent"
        class="container container--bleed style-transparent" style="padding:1rem;">
        <div class="promo__background-image" :class="model?.elements?.backgroundImageStyle?.value[0]?.codename">
            <picture>
                <NuxtImg provider="kontentAiProvider" alt="Container image"
                    v-for="(item, index) in model?.elements.backgroundImage.value" :src="item.url">
                </NuxtImg>
            </picture>
        </div>

        <div v-if="getImageAllignmentByModel(model) == ImageAlignmentEnum.None || getImageAllignmentByModel(model) == ImageAlignmentEnum.Left"
            class="promo promo--left container container--50-50">

            <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                <picture class="promo__picture" v-bind:[attributes.elementCodename]="type.image.codename">
                    <NuxtImg provider="kontentAiProvider" v-for="(item, index) in model?.elements.image.value"
                        :src="item.url"></NuxtImg>
                </picture>
            </div>

            <div class="promo__col container__col container__col--50">
                <div class="promo__text">
                    <h2 class="promo__title" v-bind:[attributes.elementCodename]="type.title.codename">{{
                        model?.elements.title.value }}</h2>
                    <div class="rich-text promo__description" v-bind:[attributes.elementCodename]="type.content.codename">
                        <RichText :value="model?.elements.content.value" />
                    </div>
                    <CallToAction v-for="(cta) in model?.elements.cta.value"
                        class="router-link-active router-link-exact-active promo__button btn btn--primary btn--black-hover"
                        :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                </div>
            </div>
        </div>

        <div v-if="getImageAllignmentByModel(model) == ImageAlignmentEnum.Right"
            class="promo promo--left container container--50-50">

            <div class="promo__col container__col container__col--50">
                <div class="promo__text">
                    <h2 class="promo__title" v-bind:[attributes.elementCodename]="type.title.codename">{{
                        model?.elements.title.value }} </h2>
                    <div class="rich-text promo__description" v-bind:[attributes.elementCodename]="type.content.codename">
                        <RichText :value="model?.elements.content.value" />
                    </div>
                </div>
            </div>

            <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                <picture class="promo__picture" v-bind:[attributes.elementCodename]="type.image.codename">
                    <NuxtImg provider="kontentAiProvider" v-for="(item, index) in model?.elements.image.value"
                        :src="item.url"></NuxtImg>
                </picture>
            </div>
        </div>
    </section>
</template>


<script setup lang="ts">

import type { IContentItem, IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { type CallToActionModel, type N5050BlockModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.n50_50_block.elements;

enum StyleTypeEnum {
    None,
    ImageFill,
    ImageWithBorder,
    Transparent
}

enum ImageAlignmentEnum {
    None,
    Left,
    Right
}

const styleMaps = new Map<string, StyleTypeEnum>([
    ['(None)', StyleTypeEnum.None],
    ['Image Fill', StyleTypeEnum.ImageFill],
    ['Image with Border', StyleTypeEnum.ImageWithBorder],
    ['Transparent', StyleTypeEnum.Transparent],
]);

const imageAllignmentMaps = new Map<string, ImageAlignmentEnum>([
    ['(None)', ImageAlignmentEnum.Left],
    ['Left', ImageAlignmentEnum.Left],
    ['Right', ImageAlignmentEnum.Right],
]);

function getStyleEnumByModel(model: N5050BlockModel): StyleTypeEnum {

    if (!model) return StyleTypeEnum.None;
    if (model?.elements.style && model?.elements.style.value && model?.elements.style.value.length > 0) {

        let style = model?.elements.style.value[0].name;
        return styleMaps.get(style) ?? StyleTypeEnum.None;

    }

    return StyleTypeEnum.None;

}

function getImageAllignmentByModel(model: N5050BlockModel): ImageAlignmentEnum {
    if (!model) return ImageAlignmentEnum.None;

    if (model?.elements.imageAlignment && model?.elements.imageAlignment.value && model?.elements.imageAlignment.value.length > 0) {

        let style = model?.elements.imageAlignment.value[0].name;
        return imageAllignmentMaps.get(style) ?? ImageAlignmentEnum.None;
    }


    return ImageAlignmentEnum.None;
}


const props = defineProps<{ model: N5050BlockModel }>();
const pageStore = usePageStore()

</script>

<style lang="scss" scoped>@use "~/assets/scss/promo.scss";
@use "~/assets/scss/n5050.scss";</style>