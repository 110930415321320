/**
 * Generated by '@kontent-ai/model-generator@6.5.1'
 */
export const contentTypes = {
    /**
     *   Navigation Item
     */
    navigation_item: {
        codename: 'navigation_item',
        id: '2a132724-0e69-4ca3-b0a7-d0dd666db894',
        externalId: undefined,
        name: '🌎Navigation Item',
        elements: {
            /**
             * External URL (text)
             */
            external_url: {
                codename: 'external_url',
                id: 'f66553a8-24a9-4279-86b2-2fe914f819eb',
                externalId: undefined,
                name: 'External URL',
                required: false,
                type: 'text'
            },

            /**
             * Page (modular_content)
             */
            page: {
                codename: 'page',
                id: '1b7f2838-90e1-4be4-a432-9c49fea121e9',
                externalId: undefined,
                name: 'Page',
                required: false,
                type: 'modular_content'
            },

            /**
             * Sub Items (subpages)
             */
            sub_items: {
                codename: 'sub_items',
                id: '05e7a913-ceaf-4ab7-aeb0-cd4fa905e968',
                externalId: undefined,
                name: 'Sub Items',
                required: false,
                type: 'subpages'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '231abd00-1140-421a-9f52-95ec879d64b6',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     *   Navigation Section
     */
    navigation_section: {
        codename: 'navigation_section',
        id: '842ef694-35f3-407e-a8c1-c8b35790c8c5',
        externalId: undefined,
        name: '🌎Navigation Section',
        elements: {
            /**
             * Sub Items (subpages)
             */
            sub_items: {
                codename: 'sub_items',
                id: '2b94d838-df8e-44ce-bb5c-87ba68417a1d',
                externalId: undefined,
                name: 'Sub Items',
                required: false,
                type: 'subpages'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '2ac1711f-68f3-410f-9f78-2f91c00a4d4d',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     *   Script Tag
     */
    script_tag: {
        codename: 'script_tag',
        id: '8ba09495-ca59-4a89-b7cd-b7ae3fcd6f7d',
        externalId: undefined,
        name: '🌎Script Tag',
        elements: {
            /**
             * Inner Code (text)
             *
             * Enter the script inner code only without the <script></script> tag here.
             */
            inner_code: {
                codename: 'inner_code',
                id: '614fc299-53d9-4911-82e5-33785416336f',
                externalId: undefined,
                name: 'Inner Code',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     *   Site Configuration
     */
    site_configuration: {
        codename: 'site_configuration',
        id: '095bebf3-5a9b-4ead-b4a3-c6588300a2c9',
        externalId: undefined,
        name: '🌎Site Configuration',
        elements: {
            /**
             * Admin Emails (text)
             *
             * Please separate emails with a comma.
             */
            admin_emails: {
                codename: 'admin_emails',
                id: 'aeced5c3-391c-4876-9ff9-d7a8bd3761dd',
                externalId: undefined,
                name: 'Admin Emails',
                required: false,
                type: 'text'
            },

            /**
             * Bcc Email (text)
             */
            bcc_email: {
                codename: 'bcc_email',
                id: '7a824ee6-fee9-49e4-9b1b-13a7f9663f2f',
                externalId: undefined,
                name: 'Bcc Email',
                required: false,
                type: 'text'
            },

            /**
             * Favicon (asset)
             */
            favicon: {
                codename: 'favicon',
                id: '9dca1821-57e4-4982-8902-a3c9ea8df19e',
                externalId: undefined,
                name: 'Favicon',
                required: false,
                type: 'asset'
            },

            /**
             * Form Interested Topics (modular_content)
             */
            form_interested_topics: {
                codename: 'form_interested_topics',
                id: '0d440365-0b34-4c53-8e34-b36bc93eae9a',
                externalId: undefined,
                name: 'Form Interested Topics',
                required: false,
                type: 'modular_content'
            },

            /**
             * Navigation Footer (modular_content)
             */
            navigation_footer: {
                codename: 'navigation_footer',
                id: 'c41bf278-6b29-43dd-b834-cf147b0683fa',
                externalId: undefined,
                name: 'Navigation Footer',
                required: false,
                type: 'modular_content'
            },

            /**
             * Navigation Header (modular_content)
             */
            navigation_header: {
                codename: 'navigation_header',
                id: '6f535439-5d33-462f-ac88-42816f24c864',
                externalId: undefined,
                name: 'Navigation Header',
                required: false,
                type: 'modular_content'
            },

            /**
             * Script Tags (modular_content)
             *
             * Tags will be placed inside the <head> element.
             */
            script_tags: {
                codename: 'script_tags',
                id: '8cb2bfc9-4e46-4b29-b489-d1b576767044',
                externalId: undefined,
                name: 'Script Tags',
                required: false,
                type: 'modular_content'
            },

            /**
             * Sender Email (text)
             */
            sender_email: {
                codename: 'sender_email',
                id: 'c4aca0f8-6dce-419b-bf60-85ee46e75646',
                externalId: undefined,
                name: 'Sender Email',
                required: false,
                type: 'text'
            },

            /**
             * Site Name (text)
             */
            site_name: {
                codename: 'site_name',
                id: '6613f9ce-65a3-4c45-b0b8-1978f1638e91',
                externalId: undefined,
                name: 'Site Name',
                required: true,
                type: 'text'
            },

            /**
             * Site Short Name (text)
             */
            site_short_name: {
                codename: 'site_short_name',
                id: '9d287f8b-3bb5-4641-b008-02626b7346d1',
                externalId: undefined,
                name: 'Site Short Name',
                required: false,
                type: 'text'
            },

            /**
             * Social Media Links (modular_content)
             */
            social_media_links: {
                codename: 'social_media_links',
                id: 'f6cf5685-35ac-4aff-8b4c-cee5751aa5c3',
                externalId: undefined,
                name: 'Social Media Links',
                required: false,
                type: 'modular_content'
            },

            /**
             * Website Logo (asset)
             */
            website_logo: {
                codename: 'website_logo',
                id: '6a65797c-2b6c-46fc-9863-ed61041dbd3e',
                externalId: undefined,
                name: 'Website Logo',
                required: false,
                type: 'asset'
            }
        }
    },

    /**
     *   Social Media Link
     */
    social_media_link: {
        codename: 'social_media_link',
        id: '0881933a-52e0-4e6b-a637-5e027c942553',
        externalId: undefined,
        name: '🌎Social Media Link',
        elements: {
            /**
             * Icon Class (text)
             */
            icon_class: {
                codename: 'icon_class',
                id: '3d64a999-7274-4792-969f-7b9539ffeca8',
                externalId: undefined,
                name: 'Icon Class',
                required: false,
                type: 'text'
            },

            /**
             * Icon Image (asset)
             */
            icon_image: {
                codename: 'icon_image',
                id: '1afbe15f-b90a-41e1-ac0e-3d2f1ac69907',
                externalId: undefined,
                name: 'Icon Image',
                required: false,
                type: 'asset'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '865a30ff-2106-40ab-bdd7-7f5c77930b27',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * URL (text)
             */
            url: {
                codename: 'url',
                id: '0e299b42-145d-42de-bbdb-e7399f3dae41',
                externalId: undefined,
                name: 'URL',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    AD Workbench
     */
    adworkbench: {
        codename: 'adworkbench',
        id: 'd8b4c029-8188-44d8-84e7-a47e2bc78ac3',
        externalId: undefined,
        name: '📄 AD Workbench',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: 'a701ff44-4e80-4b95-85e7-4d93e5f6d7a2',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Meta descriptions (text)
             */
            seo_metadata__meta_descriptions: {
                codename: 'seo_metadata__meta_descriptions',
                id: '27d69af3-0694-42e5-b9b5-11de3276b023',
                externalId: undefined,
                name: 'Meta descriptions',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '7df6f45d-1f84-4916-805e-d4b919ce7fae',
                externalId: undefined,
                name: 'Meta keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Sidebar Content (rich_text)
             */
            sidebar_content: {
                codename: 'sidebar_content',
                id: '55f8ff3d-fd8c-4866-b50f-fdf825349906',
                externalId: undefined,
                name: 'Sidebar Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'ba344fe2-c230-4929-b136-051dc3058267',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'fe38d7b5-95ca-4276-8474-ad5aba5f075d',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Apply to Submit Data
     */
    apply_to_submit_data: {
        codename: 'apply_to_submit_data',
        id: '78513b34-df68-47d3-8c6e-5250a7b3f1f8',
        externalId: undefined,
        name: '📄 Apply to Submit Data',
        elements: {
            /**
             * Meta descriptions (text)
             */
            seo_metadata__meta_descriptions: {
                codename: 'seo_metadata__meta_descriptions',
                id: '27d69af3-0694-42e5-b9b5-11de3276b023',
                externalId: undefined,
                name: 'Meta descriptions',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '7df6f45d-1f84-4916-805e-d4b919ce7fae',
                externalId: undefined,
                name: 'Meta keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Subtitle (text)
             */
            subtitle: {
                codename: 'subtitle',
                id: 'c19d362d-e9d3-4ec0-b50d-530d6b721060',
                externalId: undefined,
                name: 'Subtitle',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '8b0439f6-8aeb-490e-be7b-f6cb9a399557',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'c65803a3-f9ab-4f68-8d4b-7b8c82857cfb',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Case Study
     */
    case_study: {
        codename: 'case_study',
        id: 'f97f601c-c5e0-4373-840d-264c6a97ea2e',
        externalId: undefined,
        name: '📄 Case Study',
        elements: {
            /**
             * Case Study Title (text)
             */
            case_study_title: {
                codename: 'case_study_title',
                id: 'd4422bed-52cd-4a2c-8cc3-61a58f3142be',
                externalId: undefined,
                name: 'Case Study Title',
                required: false,
                type: 'text'
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '63b5f01c-0baf-4c80-a63a-24317766d9da',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Font Color (multiple_choice)
             */
            font_color: {
                codename: 'font_color',
                id: '61edd045-ae18-48c7-aceb-dc196badeb8f',
                externalId: undefined,
                name: 'Font Color',
                required: false,
                type: 'multiple_choice',
                options: {
                    black: {
                        name: 'Black',
                        id: '4da3524a-501e-4131-9b44-556182ef8eda',
                        codename: 'black',
                        externalId: undefined
                    },
                    white: {
                        name: 'White',
                        id: 'df7011fe-71af-498c-a9a6-cca87063baae',
                        codename: 'white',
                        externalId: undefined
                    }
                }
            },

            /**
             * Footer (rich_text)
             */
            footer: {
                codename: 'footer',
                id: '513015ff-4e71-4b9b-a40f-c377475ce45e',
                externalId: undefined,
                name: 'Footer',
                required: false,
                type: 'rich_text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '135620c4-a371-4d57-81ed-16767f4353af',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Intro Content (rich_text)
             */
            intro_content: {
                codename: 'intro_content',
                id: '84a93e24-6373-4167-b93d-e42ab2965830',
                externalId: undefined,
                name: 'Intro Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Landing Page Image (asset)
             */
            landing_page_image: {
                codename: 'landing_page_image',
                id: 'e73f503f-78d1-459b-aa77-0407dc52a9ae',
                externalId: undefined,
                name: 'Landing Page Image',
                required: false,
                type: 'asset'
            },

            /**
             * Landing Page Title (text)
             */
            landing_page_title: {
                codename: 'landing_page_title',
                id: '2fa6adb9-7887-4715-99fe-064848cad603',
                externalId: undefined,
                name: 'Landing Page Title',
                required: false,
                type: 'text'
            },

            /**
             * Meta descriptions (text)
             */
            seo_metadata__meta_descriptions: {
                codename: 'seo_metadata__meta_descriptions',
                id: '27d69af3-0694-42e5-b9b5-11de3276b023',
                externalId: undefined,
                name: 'Meta descriptions',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '7df6f45d-1f84-4916-805e-d4b919ce7fae',
                externalId: undefined,
                name: 'Meta keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Mobile Landing Page Image (asset)
             */
            mobile_landing_page_image: {
                codename: 'mobile_landing_page_image',
                id: '5c6e8b6c-16e3-45fa-917d-4b2aa3879dfd',
                externalId: undefined,
                name: 'Mobile Landing Page Image',
                required: false,
                type: 'asset'
            },

            /**
             * Sub title (text)
             */
            sub_title: {
                codename: 'sub_title',
                id: '5e4256ec-cca3-4cbc-bcd7-b677ac0366fb',
                externalId: undefined,
                name: 'Sub-title',
                required: false,
                type: 'text'
            },

            /**
             * Text Position (multiple_choice)
             */
            text_position: {
                codename: 'text_position',
                id: 'a809c833-516c-4479-a433-036ffaf65f57',
                externalId: undefined,
                name: 'Text Position',
                required: false,
                type: 'multiple_choice',
                options: {
                    left: {
                        name: 'Left',
                        id: '23b3a5bd-b9fa-4513-9d9a-476c9f148b3d',
                        codename: 'left',
                        externalId: undefined
                    },
                    right: {
                        name: 'Right',
                        id: '32126c3b-4cf7-4a0f-b171-4d24973248ec',
                        codename: 'right',
                        externalId: undefined
                    }
                }
            },

            /**
             * Url Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'd289ea19-6877-4a3f-85f7-6fcacb6d4fd5',
                externalId: undefined,
                name: 'Url Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Case Study List
     */
    case_study_list: {
        codename: 'case_study_list',
        id: '14b08045-fa92-4ced-8aca-b6dabd6fb193',
        externalId: undefined,
        name: '📄 Case Study List',
        elements: {
            /**
             * Case Study Items (modular_content)
             */
            case_study_items: {
                codename: 'case_study_items',
                id: '48906103-6c9f-432d-90c3-07732012c721',
                externalId: undefined,
                name: 'Case Study Items',
                required: false,
                type: 'modular_content'
            },

            /**
             * Sub title (rich_text)
             */
            sub_title: {
                codename: 'sub_title',
                id: '3028944f-be64-47b2-88c5-77bc4560da67',
                externalId: undefined,
                name: 'Sub-title',
                required: false,
                type: 'rich_text'
            },

            /**
             * Subpages (subpages)
             */
            subpages: {
                codename: 'subpages',
                id: '5907fef6-2346-4bf1-8c5d-f0f86def6317',
                externalId: undefined,
                name: 'Subpages',
                required: false,
                type: 'subpages'
            },

            /**
             * Title (rich_text)
             */
            title: {
                codename: 'title',
                id: '07da789f-5674-40ba-b164-4f247f686868',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title for URL (text)
             */
            title_for_url: {
                codename: 'title_for_url',
                id: '98847612-4412-42c0-acc0-292aa68d4e09',
                externalId: undefined,
                name: 'Title for URL',
                required: false,
                type: 'text'
            },

            /**
             * Update (text)
             */
            update: {
                codename: 'update',
                id: 'ed846a50-ae31-4972-a09b-897fd5f1260c',
                externalId: undefined,
                name: 'Update',
                required: false,
                type: 'text'
            },

            /**
             * Url Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '25e6e365-a80e-4837-af95-23b6b89d28e5',
                externalId: undefined,
                name: 'Url Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Data Sharing Toolkit
     */
    data_sharing_toolkit: {
        codename: 'data_sharing_toolkit',
        id: 'faca6cf1-291a-440b-b375-d17580829f53',
        externalId: undefined,
        name: '📄 Data Sharing Toolkit',
        elements: {
            /**
             * Accordion Content (rich_text)
             */
            accordion_content: {
                codename: 'accordion_content',
                id: 'ae08d98b-c55c-48c2-ae9d-bccb46ed62de',
                externalId: undefined,
                name: 'Accordion Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Banner Content (text)
             */
            banner_content: {
                codename: 'banner_content',
                id: 'd007bea3-76b2-41e2-95d0-432efe75ae63',
                externalId: undefined,
                name: 'Banner Content',
                required: false,
                type: 'text'
            },

            /**
             * Banner Title (text)
             */
            banner_title: {
                codename: 'banner_title',
                id: '81686f6c-8e7c-4158-b34e-2bbf166b9421',
                externalId: undefined,
                name: 'Banner Title',
                required: false,
                type: 'text'
            },

            /**
             * Call to Action (rich_text)
             */
            call_to_action: {
                codename: 'call_to_action',
                id: '791cc970-69db-4052-b228-b6c6aefe9f75',
                externalId: undefined,
                name: 'Call to Action',
                required: false,
                type: 'rich_text'
            },

            /**
             * Disclaimer (text)
             */
            disclaimer: {
                codename: 'disclaimer',
                id: 'f47763a0-2704-48a8-98ee-2797f4d832f6',
                externalId: undefined,
                name: 'Disclaimer',
                required: false,
                type: 'text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '09fc9ba2-7b29-44a5-99c3-1737eb05eff3',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Meta descriptions (text)
             */
            seo_metadata__meta_descriptions: {
                codename: 'seo_metadata__meta_descriptions',
                id: '27d69af3-0694-42e5-b9b5-11de3276b023',
                externalId: undefined,
                name: 'Meta descriptions',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '7df6f45d-1f84-4916-805e-d4b919ce7fae',
                externalId: undefined,
                name: 'Meta keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Sub Title (rich_text)
             */
            sub_title: {
                codename: 'sub_title',
                id: '14792b3b-cde7-4582-a0f0-72eb3e94533a',
                externalId: undefined,
                name: 'Sub Title',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '3c612cb1-7802-4b1a-bc06-2ad164f5b57c',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'e69f3887-447c-4121-98e5-81c99ebf3db1',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    External Page
     */
    external_page: {
        codename: 'external_page',
        id: '3ed5ce69-a99a-4887-9b7b-6c26ea5a401e',
        externalId: undefined,
        name: '📄 External Page',
        elements: {
            /**
             * External URL (text)
             */
            external_url: {
                codename: 'external_url',
                id: '98dbb3b6-a599-43b0-9d68-8a0997741679',
                externalId: undefined,
                name: 'External URL',
                required: true,
                type: 'text'
            },

            /**
             * Show in Site Menu (multiple_choice)
             */
            show_in_site_menu: {
                codename: 'show_in_site_menu',
                id: 'f670269e-6103-4d13-9913-f753fedbb838',
                externalId: undefined,
                name: 'Show in Site Menu',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'dac12939-3504-421a-89aa-150911b88bef',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'd637ddea-8ecf-4711-90eb-791bc0c56977',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'c7642778-8adf-42cf-b348-7997dfea3d11',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     *    Homepage
     */
    homepage: {
        codename: 'homepage',
        id: 'cee5eeb7-1862-4e41-bb88-400d56d1801d',
        externalId: undefined,
        name: '📄 Homepage',
        elements: {
            /**
             * 50 50 Block (modular_content)
             */
            n50_50_block: {
                codename: 'n50_50_block',
                id: '55e9682e-4575-41d8-bd74-c902334d9eee',
                externalId: undefined,
                name: '50/50 Block',
                required: false,
                type: 'modular_content'
            },

            /**
             * 50 50 Blocks (modular_content)
             */
            n50_50_blocks: {
                codename: 'n50_50_blocks',
                id: 'b9f8cb65-bc80-4b60-9780-1af8226240a2',
                externalId: undefined,
                name: '50/50 Blocks',
                required: false,
                type: 'modular_content'
            },

            /**
             * Banner Links (modular_content)
             */
            banner_links: {
                codename: 'banner_links',
                id: '36ff75d4-1efb-4c20-ad80-cfd92d36ba7d',
                externalId: undefined,
                name: 'Banner Links',
                required: false,
                type: 'modular_content'
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: 'd43a7d34-31f7-4e70-abd0-dd56a8e60482',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Content Banner (modular_content)
             */
            content_banner: {
                codename: 'content_banner',
                id: 'c19fd819-994f-470a-8873-6a635b147dd7',
                externalId: undefined,
                name: 'Content Banner',
                required: false,
                type: 'modular_content'
            },

            /**
             * Hero (modular_content)
             */
            hero: {
                codename: 'hero',
                id: '8e362978-d77d-4ad4-adba-7234291623f2',
                externalId: undefined,
                name: 'Hero',
                required: false,
                type: 'modular_content'
            },

            /**
             * Meta descriptions (text)
             */
            seo_metadata__meta_descriptions: {
                codename: 'seo_metadata__meta_descriptions',
                id: '27d69af3-0694-42e5-b9b5-11de3276b023',
                externalId: undefined,
                name: 'Meta descriptions',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '7df6f45d-1f84-4916-805e-d4b919ce7fae',
                externalId: undefined,
                name: 'Meta keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Partners (modular_content)
             */
            partners: {
                codename: 'partners',
                id: 'aa6bacfb-abf0-4596-8d1b-c75688609d7c',
                externalId: undefined,
                name: 'Partners',
                required: false,
                type: 'modular_content'
            },

            /**
             * Quote (modular_content)
             */
            quote: {
                codename: 'quote',
                id: '765055f7-6808-48cf-89d4-fe2954e39ac1',
                externalId: undefined,
                name: 'Quote',
                required: false,
                type: 'modular_content'
            },

            /**
             * Subpages (subpages)
             */
            subpages: {
                codename: 'subpages',
                id: 'e89a46db-1892-460a-b024-23b7e964dc32',
                externalId: undefined,
                name: 'Subpages',
                required: false,
                type: 'subpages'
            },

            /**
             * Subscription (modular_content)
             */
            subscription: {
                codename: 'subscription',
                id: '08040a59-3b6f-43af-937d-d9899c091847',
                externalId: undefined,
                name: 'Subscription',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'faf7b415-dbcc-4427-b1e2-7ff2ff4ac9a5',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '44c20ccc-b860-4d3c-a75e-8a3ab0eed5e5',
                externalId: undefined,
                name: 'URL slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Interior Page
     */
    page: {
        codename: 'page',
        id: '1c1dc2ef-5855-484f-8ee7-ffc6daae33e0',
        externalId: undefined,
        name: '📄 Interior Page',
        elements: {
            /**
             * Breadcrumb Target (modular_content)
             */
            breadcrumb_target: {
                codename: 'breadcrumb_target',
                id: '220a9e21-0cfe-4ee6-8648-55b21017990f',
                externalId: undefined,
                name: 'Breadcrumb Target',
                required: false,
                type: 'modular_content'
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: 'b39523b6-2e98-474f-9196-b01ae1453f7a',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Hero (modular_content)
             */
            hero: {
                codename: 'hero',
                id: 'a4f76392-aa29-4774-a564-b9e59fb56e6f',
                externalId: undefined,
                name: 'Hero',
                required: false,
                type: 'modular_content'
            },

            /**
             * Meta descriptions (text)
             */
            seo_metadata__meta_descriptions: {
                codename: 'seo_metadata__meta_descriptions',
                id: '27d69af3-0694-42e5-b9b5-11de3276b023',
                externalId: undefined,
                name: 'Meta descriptions',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '7df6f45d-1f84-4916-805e-d4b919ce7fae',
                externalId: undefined,
                name: 'Meta keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Section Style (multiple_choice)
             */
            section_style: {
                codename: 'section_style',
                id: '5aa35935-47d9-47c0-b91c-a5f1958e9732',
                externalId: undefined,
                name: 'Section Style',
                required: false,
                type: 'multiple_choice',
                options: {
                    full_bleed: {
                        name: 'Full Bleed',
                        id: '45b97a3e-aef3-4019-b96b-0d6a61c48ba9',
                        codename: 'full_bleed',
                        externalId: undefined
                    },
                    container: {
                        name: 'Container',
                        id: '7646bd0a-c78e-4c69-a691-e0d34985e6cf',
                        codename: 'container',
                        externalId: undefined
                    },
                    narrow_container: {
                        name: 'Narrow Container',
                        id: '3717068b-2829-4e57-9911-ff9c02e93fd9',
                        codename: 'narrow_container',
                        externalId: undefined
                    }
                }
            },

            /**
             * Subpages (subpages)
             */
            subpages: {
                codename: 'subpages',
                id: 'ec0a3aad-10c6-4c2f-b701-2f727ee1e941',
                externalId: undefined,
                name: 'Subpages',
                required: false,
                type: 'subpages'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '42dd67c6-038a-48f9-b21f-301347a73700',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * Url slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'f7f8c959-666a-45d1-89ee-055399b3e5f7',
                externalId: undefined,
                name: 'Url slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    News Item
     */
    news_item: {
        codename: 'news_item',
        id: 'bc6063a1-9a94-4e96-8730-8c788aba7544',
        externalId: undefined,
        name: '📄 News Item',
        elements: {
            /**
             * Bold Text (text)
             */
            bold_text: {
                codename: 'bold_text',
                id: '7f578e33-49f8-43cc-aed8-2cc91a0a322f',
                externalId: undefined,
                name: 'Bold Text',
                required: false,
                type: 'text'
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: 'bd550887-509e-4369-b0cb-90145064afe6',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Date (date_time)
             */
            date: {
                codename: 'date',
                id: 'f62eba83-9d4f-450d-b558-81032c4ab81a',
                externalId: undefined,
                name: 'Date',
                required: false,
                type: 'date_time'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: 'edd0c3c7-2d68-4168-9718-851d8de92c4c',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Meta descriptions (text)
             */
            seo_metadata__meta_descriptions: {
                codename: 'seo_metadata__meta_descriptions',
                id: '27d69af3-0694-42e5-b9b5-11de3276b023',
                externalId: undefined,
                name: 'Meta descriptions',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '7df6f45d-1f84-4916-805e-d4b919ce7fae',
                externalId: undefined,
                name: 'Meta keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * News Type (taxonomy)
             */
            news_type: {
                codename: 'news_type',
                id: 'e687715c-93b2-4949-a48e-814928a12d4d',
                externalId: undefined,
                name: 'News Type',
                required: true,
                type: 'taxonomy'
            },

            /**
             * Sidebar Content (rich_text)
             */
            sidebar_content: {
                codename: 'sidebar_content',
                id: '44968177-4ddf-4725-bf8f-4b8e3c1d0764',
                externalId: undefined,
                name: 'Sidebar Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Sub title (text)
             */
            sub_title: {
                codename: 'sub_title',
                id: '75b9a52d-3be3-4b8c-9a5e-f7b7e242994a',
                externalId: undefined,
                name: 'Sub-title',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '66774d13-44b1-4436-813e-5251af0940ff',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * URL slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '7119bb29-c921-4c1c-8c70-aaf990d304a5',
                externalId: undefined,
                name: 'URL slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    News Letter Page
     */
    news_letter_page: {
        codename: 'news_letter_page',
        id: 'f4df4e07-8c8d-472a-a24e-02a3e1fd68ed',
        externalId: undefined,
        name: '📄 News Letter Page',
        elements: {
            /**
             * Breadcrumb (modular_content)
             */
            breadcrumb: {
                codename: 'breadcrumb',
                id: '9671a6f2-10a9-4e8c-b72d-18916109bc94',
                externalId: undefined,
                name: 'Breadcrumb',
                required: false,
                type: 'modular_content'
            },

            /**
             * Meta descriptions (text)
             */
            seo_metadata__meta_descriptions: {
                codename: 'seo_metadata__meta_descriptions',
                id: '27d69af3-0694-42e5-b9b5-11de3276b023',
                externalId: undefined,
                name: 'Meta descriptions',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '7df6f45d-1f84-4916-805e-d4b919ce7fae',
                externalId: undefined,
                name: 'Meta keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Newsletter list (modular_content)
             */
            newsletter_list: {
                codename: 'newsletter_list',
                id: '7f3e1b1c-6623-497b-b450-5d1096acc84e',
                externalId: undefined,
                name: 'Newsletter list',
                required: false,
                type: 'modular_content'
            },

            /**
             * Sub Pages (subpages)
             */
            sub_pages: {
                codename: 'sub_pages',
                id: '72c29545-f5a5-445d-9d9b-a1eca532ff30',
                externalId: undefined,
                name: 'Sub Pages',
                required: false,
                type: 'subpages'
            },

            /**
             * Sub Title (text)
             */
            sub_title: {
                codename: 'sub_title',
                id: '4f1b0719-404a-4c75-9586-359d08ec71ed',
                externalId: undefined,
                name: 'Sub Title',
                required: false,
                type: 'text'
            },

            /**
             * Subscription (modular_content)
             */
            subscription: {
                codename: 'subscription',
                id: 'c45f647b-f6de-4403-84e5-f86e8df38b6c',
                externalId: undefined,
                name: 'Subscription',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'd90bcc75-7795-493a-992b-776c59c10fbd',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            },

            /**
             * Url slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '23de4b4b-d644-49d9-8218-1f0353fb1c55',
                externalId: undefined,
                name: 'Url slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Newsletter Item
     */
    _newsletter_item: {
        codename: '_newsletter_item',
        id: 'd61baf45-3a13-4f75-927f-15a87df17861',
        externalId: undefined,
        name: '📄 Newsletter Item',
        elements: {
            /**
             * Back to News Letters (modular_content)
             */
            back_to_news_letters: {
                codename: 'back_to_news_letters',
                id: 'a3892844-3b87-4eb5-849b-b938acc0ceea',
                externalId: undefined,
                name: 'Back to News Letters',
                required: false,
                type: 'modular_content'
            },

            /**
             * Date (date_time)
             */
            date: {
                codename: 'date',
                id: '2bdb5828-402a-4028-a76a-d9b173326a1e',
                externalId: undefined,
                name: 'Date',
                required: false,
                type: 'date_time'
            },

            /**
             * Html File (asset)
             */
            html_file: {
                codename: 'html_file',
                id: '0ceb7540-9577-4855-b161-6fd3a0576156',
                externalId: undefined,
                name: 'Html File',
                required: false,
                type: 'asset'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: 'e1719e0f-28c0-40dc-9a6d-4bb08e507bf9',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Meta descriptions (text)
             */
            seo_metadata__meta_descriptions: {
                codename: 'seo_metadata__meta_descriptions',
                id: '27d69af3-0694-42e5-b9b5-11de3276b023',
                externalId: undefined,
                name: 'Meta descriptions',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '7df6f45d-1f84-4916-805e-d4b919ce7fae',
                externalId: undefined,
                name: 'Meta keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '12562840-84a4-4080-ab06-26af19e2272e',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Url slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'c5f2c159-c111-41be-a8fb-34e9ae520ff7',
                externalId: undefined,
                name: 'Url slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *   Anchor Tag
     */
    anchor_tag: {
        codename: 'anchor_tag',
        id: 'f7c122f6-fccf-4a9d-aabe-beae5ed7e2ad',
        externalId: undefined,
        name: '🔗Anchor Tag',
        elements: {
            /**
             * ID (text)
             */
            id: {
                codename: 'id',
                id: 'aa40c370-579b-4e62-9f55-ce4bfae38e0b',
                externalId: undefined,
                name: 'ID',
                required: true,
                type: 'text'
            },

            /**
             * Show Tag Icon (multiple_choice)
             */
            show_tag_icon: {
                codename: 'show_tag_icon',
                id: '248dc4e9-9e9c-4335-984d-af43ec801c57',
                externalId: undefined,
                name: 'Show Tag Icon',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: '39a27443-1db1-4604-86ea-7f5e23e59c05',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'ea3e4287-db76-4dcd-b1ec-c78729294c8d',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     *    Accordion Item
     */
    accordion_item: {
        codename: 'accordion_item',
        id: '459f2822-ef49-4214-adbb-b75eaf72443f',
        externalId: undefined,
        name: '🔠 Accordion Item',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: 'f35768f7-ad59-4c79-8d2c-11c716306959',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '033166fb-ffdb-4dd7-a3ee-01988ca7c5c0',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Board Member
     */
    advisory_board_member: {
        codename: 'advisory_board_member',
        id: 'd540a477-8d8d-4e1a-9505-b9cd9f71a07e',
        externalId: undefined,
        name: '🔠 Board Member',
        elements: {
            /**
             * External Link (text)
             */
            list_page_path: {
                codename: 'list_page_path',
                id: '70fd60d6-bc4d-422e-994e-798193147e3d',
                externalId: undefined,
                name: 'External Link',
                required: false,
                type: 'text'
            },

            /**
             * Member name (text)
             */
            member_name: {
                codename: 'member_name',
                id: 'd5871201-f7b9-41d9-b23d-93123d35e0ed',
                externalId: undefined,
                name: 'Member name',
                required: false,
                type: 'text'
            },

            /**
             * Partner (modular_content)
             */
            partner: {
                codename: 'partner',
                id: 'fab85034-bf63-45d8-ae53-0de5507f3cb5',
                externalId: undefined,
                name: 'Partner',
                required: false,
                type: 'modular_content'
            },

            /**
             * Rich Text (rich_text)
             */
            title___text__rich_text: {
                codename: 'title___text__rich_text',
                id: '0ecca36f-9462-4cfe-a754-acbd8212c155',
                externalId: undefined,
                name: 'Rich Text',
                required: false,
                type: 'rich_text',

                snippetCodename: 'title___text'
            },

            /**
             * Text (text)
             */
            title___text__text: {
                codename: 'title___text__text',
                id: '3903c823-ff55-46ba-8641-d7bc80ccfebb',
                externalId: undefined,
                name: 'Text',
                required: false,
                type: 'text',

                snippetCodename: 'title___text'
            },

            /**
             * Title (text)
             */
            title___text__title: {
                codename: 'title___text__title',
                id: '5a2b3176-5807-4f6d-a0a0-dc91523d34e0',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text',

                snippetCodename: 'title___text'
            }
        }
    },

    /**
     *    Call to Action
     */
    call_to_action: {
        codename: 'call_to_action',
        id: '04d66e3c-5318-4aa5-9334-c91be137dfab',
        externalId: undefined,
        name: '🔠 Call to Action',
        elements: {
            /**
             * Email Address (text)
             *
             * Enter an email address.
             */
            email_address: {
                codename: 'email_address',
                id: 'dbe78183-7a13-44aa-997c-3d1fbe7c4d04',
                externalId: undefined,
                name: 'Email Address',
                required: false,
                type: 'text'
            },

            /**
             * Generic Asset Target (asset)
             *
             * Choose an Asset uploaded to Kontent that was not created as a content type
             */
            generic_asset_target: {
                codename: 'generic_asset_target',
                id: '41bdaa82-17cd-461a-ad4d-7d66a65d5e2f',
                externalId: undefined,
                name: 'Generic Asset Target',
                required: false,
                type: 'asset'
            },

            /**
             * Link Title (text)
             *
             * This is the label for the link
             */
            title: {
                codename: 'title',
                id: 'ea95d0fa-f699-4f5d-bc5d-15bdf1e85453',
                externalId: undefined,
                name: 'Link Title',
                required: true,
                type: 'text'
            },

            /**
             * Linked Target (modular_content)
             *
             * Choose an existing content item as the target of the link.
             */
            linked_target: {
                codename: 'linked_target',
                id: '2f9a513d-b216-4f69-a7fc-799573b6fbcd',
                externalId: undefined,
                name: 'Linked Target',
                required: false,
                type: 'modular_content'
            },

            /**
             * Manual Target (text)
             *
             * The URL of a target for External Links. The link should be the full URL including "http://" or "https://"
             */
            manual_target: {
                codename: 'manual_target',
                id: 'c1fed04f-6d5d-4552-b9c6-2658d7cae23b',
                externalId: undefined,
                name: 'Manual Target',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Executive Board Member
     */
    executive_board_member: {
        codename: 'executive_board_member',
        id: '9eaa07ad-c894-4e69-9dfa-7e9c01839fc9',
        externalId: undefined,
        name: '🔠 Executive Board Member',
        elements: {
            /**
             * Member name (text)
             */
            member_name: {
                codename: 'member_name',
                id: '04c1e2ad-8af6-4de6-a8d7-a6a08d1e1b44',
                externalId: undefined,
                name: 'Member name',
                required: false,
                type: 'text'
            },

            /**
             * Partner (modular_content)
             */
            partner: {
                codename: 'partner',
                id: 'b3fb4e79-39f4-4d93-b2d1-c4f94df610dc',
                externalId: undefined,
                name: 'Partner',
                required: false,
                type: 'modular_content'
            },

            /**
             * Rich Text (rich_text)
             */
            title___text__rich_text: {
                codename: 'title___text__rich_text',
                id: '0ecca36f-9462-4cfe-a754-acbd8212c155',
                externalId: undefined,
                name: 'Rich Text',
                required: false,
                type: 'rich_text',

                snippetCodename: 'title___text'
            },

            /**
             * Text (text)
             */
            title___text__text: {
                codename: 'title___text__text',
                id: '3903c823-ff55-46ba-8641-d7bc80ccfebb',
                externalId: undefined,
                name: 'Text',
                required: false,
                type: 'text',

                snippetCodename: 'title___text'
            },

            /**
             * Title (text)
             */
            title___text__title: {
                codename: 'title___text__title',
                id: '5a2b3176-5807-4f6d-a0a0-dc91523d34e0',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text',

                snippetCodename: 'title___text'
            }
        }
    },

    /**
     *    Fellow
     */
    fellow: {
        codename: 'fellow',
        id: 'c2bc0dab-6660-4e9d-8490-4555196cd847',
        externalId: undefined,
        name: '🔠 Fellow',
        elements: {
            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '33a31422-beb2-4b1a-a344-92dc0005cd68',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Name (text)
             */
            name: {
                codename: 'name',
                id: '74c6f26e-af16-404b-a262-4c1613f73d01',
                externalId: undefined,
                name: 'Name',
                required: false,
                type: 'text'
            },

            /**
             * Professional Background (rich_text)
             */
            professional_background: {
                codename: 'professional_background',
                id: '8a7127de-a6d3-4540-9eb7-8a9c81d18f62',
                externalId: undefined,
                name: 'Professional Background',
                required: false,
                type: 'rich_text'
            },

            /**
             * Proposed Research (rich_text)
             */
            proposed_research: {
                codename: 'proposed_research',
                id: '1d6649f2-9bb4-4630-b0c7-7d7626b861b8',
                externalId: undefined,
                name: 'Proposed Research',
                required: false,
                type: 'rich_text'
            },

            /**
             * Research Focus (rich_text)
             */
            research_focus: {
                codename: 'research_focus',
                id: 'fa1ba635-29fc-4a5f-a6ba-53d702fbfabd',
                externalId: undefined,
                name: 'Research Focus',
                required: false,
                type: 'rich_text'
            }
        }
    },

    /**
     *    Global Technical Advisory Committee Member
     */
    _global_technical_advisory_committee_member: {
        codename: '_global_technical_advisory_committee_member',
        id: '77a11fb6-8482-4cb0-bb80-0f76780e37c7',
        externalId: undefined,
        name: '🔠 Global Technical Advisory Committee Member',
        elements: {
            /**
             * External Link (text)
             */
            external_link: {
                codename: 'external_link',
                id: '07d667f0-1a74-4a80-9a26-3ecbf97f80e5',
                externalId: undefined,
                name: 'External Link',
                required: false,
                type: 'text'
            },

            /**
             * Rich Text (rich_text)
             */
            title___text__rich_text: {
                codename: 'title___text__rich_text',
                id: '0ecca36f-9462-4cfe-a754-acbd8212c155',
                externalId: undefined,
                name: 'Rich Text',
                required: false,
                type: 'rich_text',

                snippetCodename: 'title___text'
            },

            /**
             * Text (text)
             */
            title___text__text: {
                codename: 'title___text__text',
                id: '3903c823-ff55-46ba-8641-d7bc80ccfebb',
                externalId: undefined,
                name: 'Text',
                required: false,
                type: 'text',

                snippetCodename: 'title___text'
            },

            /**
             * Title (text)
             */
            title___text__title: {
                codename: 'title___text__title',
                id: '5a2b3176-5807-4f6d-a0a0-dc91523d34e0',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text',

                snippetCodename: 'title___text'
            }
        }
    },

    /**
     *    Interested Topic
     */
    interested_topic: {
        codename: 'interested_topic',
        id: 'aab76c86-8b5a-4a52-9c8d-cc1fea8cce1f',
        externalId: undefined,
        name: '🔠 Interested Topic',
        elements: {
            /**
             * CRM Field (text)
             */
            crm_field: {
                codename: 'crm_field',
                id: '88d728f7-a646-4e4f-93e1-63a7ba51c420',
                externalId: undefined,
                name: 'CRM Field',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '10c18b24-4cf9-4a40-9a69-be1fdb1c6f48',
                externalId: undefined,
                name: 'Title',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     *    News Banner
     */
    news_banner: {
        codename: 'news_banner',
        id: '5f874e6b-1c6c-4e49-8769-7079384df77c',
        externalId: undefined,
        name: '🔠 News Banner',
        elements: {
            /**
             * Banner Image (asset)
             */
            banner_image: {
                codename: 'banner_image',
                id: '2cd2bc02-eb13-4e24-a441-8da404c0c738',
                externalId: undefined,
                name: 'Banner Image',
                required: false,
                type: 'asset'
            },

            /**
             * Banner Title (text)
             */
            banner_title: {
                codename: 'banner_title',
                id: 'f3e1b0d0-9f63-46fa-8f11-52619f71f712',
                externalId: undefined,
                name: 'Banner Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Partner
     */
    partner: {
        codename: 'partner',
        id: '8e77f873-3fb7-4ddc-b75c-b3cfa361ecfe',
        externalId: undefined,
        name: '🔠 Partner',
        elements: {
            /**
             * Logo (asset)
             */
            logo: {
                codename: 'logo',
                id: 'ce30c0e3-a28d-43aa-8ba7-3f31d3f26041',
                externalId: undefined,
                name: 'Logo',
                required: false,
                type: 'asset'
            },

            /**
             * Partner Name (text)
             */
            name: {
                codename: 'name',
                id: '44766b2e-533d-47f5-989f-d9d66e5f783d',
                externalId: undefined,
                name: 'Partner Name',
                required: false,
                type: 'text'
            },

            /**
             * URL (text)
             */
            url: {
                codename: 'url',
                id: '42ee640b-22f8-4a95-a3b7-f55e95f0aa05',
                externalId: undefined,
                name: 'URL',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Rich Text   Table
     */
    rich_text___table: {
        codename: 'rich_text___table',
        id: '63878b14-3ff6-47d5-bafe-224be34330b1',
        externalId: undefined,
        name: '🔠 Rich Text - Table',
        elements: {
            /**
             * Table Content (rich_text)
             */
            table_content: {
                codename: 'table_content',
                id: '01bb700c-a009-4e06-a3fc-f4085f644c9b',
                externalId: undefined,
                name: 'Table Content',
                required: false,
                type: 'rich_text'
            }
        }
    },

    /**
     *    Team Member
     */
    team_member: {
        codename: 'team_member',
        id: 'a67e3880-5e08-46a1-8b0e-a0c7090681ff',
        externalId: undefined,
        name: '🔠 Team Member',
        elements: {
            /**
             * Bio (rich_text)
             */
            bio: {
                codename: 'bio',
                id: 'bdde360d-a06a-4580-8553-b368aa21dbf5',
                externalId: undefined,
                name: 'Bio',
                required: false,
                type: 'rich_text'
            },

            /**
             * External Link (text)
             */
            external_link: {
                codename: 'external_link',
                id: '98eb435d-d683-4023-9b49-43db6fff72f3',
                externalId: undefined,
                name: 'External Link',
                required: false,
                type: 'text'
            },

            /**
             * Full Display Name (text)
             */
            full_display_name: {
                codename: 'full_display_name',
                id: '2929e80f-a2d8-4eb4-a22d-5bcd4a93c48b',
                externalId: undefined,
                name: 'Full Display Name',
                required: false,
                type: 'text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: 'f9d02c4f-e9b3-4b13-98f2-03a101753677',
                externalId: undefined,
                name: 'Image',
                required: true,
                type: 'asset'
            },

            /**
             * Name (text)
             */
            name: {
                codename: 'name',
                id: '8054ee86-a254-473e-a0d5-7649dbee3dc8',
                externalId: undefined,
                name: 'Name',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '4a1f6435-0d63-4919-a435-85664bce653e',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Tile
     */
    tile: {
        codename: 'tile',
        id: '897675d7-52dd-42bd-997e-8331712af103',
        externalId: undefined,
        name: '🔠 Tile',
        elements: {
            /**
             * Background Image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: '3a94d27e-14ec-4f3f-a22b-57ecf47fae50',
                externalId: undefined,
                name: 'Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * External Link (text)
             */
            external_link: {
                codename: 'external_link',
                id: 'feb90352-b50b-44ae-ac90-467e38c3875d',
                externalId: undefined,
                name: 'External Link',
                required: false,
                type: 'text'
            },

            /**
             * Internal Item Link (modular_content)
             */
            item_link: {
                codename: 'item_link',
                id: '8c1c8e1a-9d5b-4c1e-b3ea-bdff0cab8c25',
                externalId: undefined,
                name: 'Internal Item Link',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'af63cb7a-6aea-4532-b9f9-760aa0597419',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    50 50 Block
     */
    n50_50_block: {
        codename: 'n50_50_block',
        id: 'd400d0bd-6dc4-4061-adcc-40ba6db3ba2e',
        externalId: undefined,
        name: '🧩 50/50 Block',
        elements: {
            /**
             * Background Image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: '80b7cba8-184e-4422-a4e2-a7761b00ebb5',
                externalId: undefined,
                name: 'Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * Background Image Style (multiple_choice)
             */
            background_image_style: {
                codename: 'background_image_style',
                id: 'e8e9b6a0-d2db-431f-bf30-574c983ffc91',
                externalId: undefined,
                name: 'Background Image Style',
                required: false,
                type: 'multiple_choice',
                options: {
                    full_width: {
                        name: 'Full Width',
                        id: '9144f3ab-f401-4ebb-942d-beacb8a4401a',
                        codename: 'full_width',
                        externalId: undefined
                    },
                    half_width_left: {
                        name: 'Half Width Left',
                        id: '63cdb59a-61d7-445c-bed0-38492cc242ab',
                        codename: 'half_width_left',
                        externalId: undefined
                    },
                    half_width_right: {
                        name: 'Half Width Right',
                        id: 'f744a63e-ea30-4fda-8f04-d2ac5fe1882f',
                        codename: 'half_width_right',
                        externalId: undefined
                    }
                }
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '237b10fe-8de1-45bd-906d-aadffc158603',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * CTA (modular_content)
             */
            cta: {
                codename: 'cta',
                id: 'e228d386-3621-4d02-bfca-a9bf1552c120',
                externalId: undefined,
                name: 'CTA',
                required: false,
                type: 'modular_content'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '700a715c-5912-4360-890a-0712ed1ac99f',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Primary Image Alignment (multiple_choice)
             */
            image_alignment: {
                codename: 'image_alignment',
                id: '3637d3b5-e211-489e-8116-a9b99ea33f65',
                externalId: undefined,
                name: 'Primary Image Alignment',
                required: false,
                type: 'multiple_choice',
                options: {
                    left: {
                        name: 'Left',
                        id: 'c6e618c8-6392-4b95-8b63-49e9bb47b5bf',
                        codename: 'left',
                        externalId: undefined
                    },
                    right: {
                        name: 'Right',
                        id: '8241c534-e36c-4dfc-8c93-1f073ea99e54',
                        codename: 'right',
                        externalId: undefined
                    }
                }
            },

            /**
             * Primary Image Style (multiple_choice)
             */
            style: {
                codename: 'style',
                id: 'f9d1e829-eb9c-46fd-8f23-d8c1d91667ff',
                externalId: undefined,
                name: 'Primary Image Style',
                required: false,
                type: 'multiple_choice',
                options: {
                    image_fill: {
                        name: 'Image Fill',
                        id: 'b85ee1cd-5de5-4871-8643-3ef26a820eeb',
                        codename: 'image_fill',
                        externalId: undefined
                    },
                    image_with_border: {
                        name: 'Image with Border',
                        id: 'b53b7fdd-bd96-416f-a107-37741d4e116c',
                        codename: 'image_with_border',
                        externalId: undefined
                    },
                    transparent: {
                        name: 'Transparent',
                        id: 'ff280a55-7736-4210-ba54-0cc526885b44',
                        codename: 'transparent',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '44bd82e3-4adc-401a-af07-4ff292741d39',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    50 50 Video Block
     */
    n50_50_video_block: {
        codename: 'n50_50_video_block',
        id: '631d9937-d310-4c4d-bf69-92de92cc018c',
        externalId: undefined,
        name: '🧩 50/50 Video Block',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '492a2869-2d5c-47f2-95e0-899eafb18ba8',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * CTA (modular_content)
             */
            cta: {
                codename: 'cta',
                id: '329de216-a03d-44af-829d-ec2e702f15ce',
                externalId: undefined,
                name: 'CTA',
                required: false,
                type: 'modular_content'
            },

            /**
             * Image Alignment (multiple_choice)
             */
            image_alignment: {
                codename: 'image_alignment',
                id: '0e792520-aab6-485c-9758-001a0d62bbd8',
                externalId: undefined,
                name: 'Image Alignment',
                required: false,
                type: 'multiple_choice',
                options: {
                    left: {
                        name: 'Left',
                        id: '601a3ada-881a-4172-8527-2e61733604bb',
                        codename: 'left',
                        externalId: undefined
                    },
                    right: {
                        name: 'Right',
                        id: '1d8d557d-113e-4e42-a9ea-a2727f258d2a',
                        codename: 'right',
                        externalId: undefined
                    }
                }
            },

            /**
             * Style (multiple_choice)
             */
            style: {
                codename: 'style',
                id: 'ff921e34-dcde-49db-87cb-8adf3fc872bf',
                externalId: undefined,
                name: 'Style',
                required: false,
                type: 'multiple_choice',
                options: {
                    image_fill: {
                        name: 'Image Fill',
                        id: '40002bce-f3a1-4736-97c0-29d07ba1032d',
                        codename: 'image_fill',
                        externalId: undefined
                    },
                    image_with_border: {
                        name: 'Image with Border',
                        id: 'd9dbc012-2099-4516-adba-b07abd35e88b',
                        codename: 'image_with_border',
                        externalId: undefined
                    },
                    transparent: {
                        name: 'Transparent',
                        id: 'b731f66f-d3ae-4263-aa1c-cdffae3429a4',
                        codename: 'transparent',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '246d0f8e-cc14-4429-bd11-c8c8a3a3ef4c',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Video Embed Code (text)
             */
            video_embed_code: {
                codename: 'video_embed_code',
                id: 'dd4eff07-35d1-42fb-a6e2-dff349181480',
                externalId: undefined,
                name: 'Video Embed Code',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Accordion List
     */
    accordion_list: {
        codename: 'accordion_list',
        id: 'fed066aa-bb40-457e-bb6c-586fb065793c',
        externalId: undefined,
        name: '🧩 Accordion List',
        elements: {
            /**
             * Accordion Item (modular_content)
             */
            accordion_item: {
                codename: 'accordion_item',
                id: '0dc30776-fb9d-45d4-a9d5-769253c952fe',
                externalId: undefined,
                name: 'Accordion Item',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '9cd5fc92-61b2-4c3b-a597-e84d04a9e55b',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Advisory Board Member List
     */
    advisory_board_member_list: {
        codename: 'advisory_board_member_list',
        id: 'dab3fecb-4897-4fb8-a344-1a7ff6d67bb5',
        externalId: undefined,
        name: '🧩 Advisory Board Member List',
        elements: {
            /**
             * Member list (modular_content)
             */
            member_list: {
                codename: 'member_list',
                id: '5b4ce2a0-57fe-45a0-bb5b-917e1a0faf4b',
                externalId: undefined,
                name: 'Member list',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '9c4a4584-f5a0-4dea-9123-25a0960beb8b',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Banner   Content
     */
    banner___content: {
        codename: 'banner___content',
        id: 'f4e146e5-eac8-4a83-bd8c-75e58e3d1e44',
        externalId: undefined,
        name: '🧩 Banner - Content',
        elements: {
            /**
             * Background (multiple_choice)
             */
            background: {
                codename: 'background',
                id: '6b1d4578-2f03-4282-b447-5183b8ca839e',
                externalId: undefined,
                name: 'Background',
                required: false,
                type: 'multiple_choice',
                options: {
                    none: {
                        name: 'None',
                        id: 'ff59bfaf-dde3-4ccd-96cd-81cae1571c4c',
                        codename: 'none',
                        externalId: undefined
                    },
                    grey: {
                        name: 'Grey',
                        id: 'ad3b144e-fcb7-4db3-85eb-a96e50b6d5b1',
                        codename: 'grey',
                        externalId: undefined
                    }
                }
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '9551f385-684c-4581-851a-75419357668e',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * CTA (modular_content)
             */
            cta: {
                codename: 'cta',
                id: '07416d1b-375f-4a0d-9aad-f5b2815f72bd',
                externalId: undefined,
                name: 'CTA',
                required: false,
                type: 'modular_content'
            },

            /**
             * Style (multiple_choice)
             */
            style: {
                codename: 'style',
                id: '9392e0c8-6b13-4856-8c50-944bf682f3b3',
                externalId: undefined,
                name: 'Style',
                required: false,
                type: 'multiple_choice',
                options: {
                    centered: {
                        name: 'Centered',
                        id: '1b49a47f-c4d4-4a0f-83a9-ec3360df9ca5',
                        codename: 'centered',
                        externalId: undefined
                    },
                    left: {
                        name: 'Left',
                        id: '952945a3-aee7-4299-975a-7baf2c9d6935',
                        codename: 'left',
                        externalId: undefined
                    },
                    light: {
                        name: 'Right',
                        id: '22f10468-844e-40f7-bd33-4d11f5b35016',
                        codename: 'light',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '4912475a-8060-4a09-b884-539cdc66d6e2',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Banner   Link
     */
    banner___link: {
        codename: 'banner___link',
        id: 'aa7b2bcc-1541-4efa-be19-267d6c763ff5',
        externalId: undefined,
        name: '🧩 Banner - Link',
        elements: {
            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '0ca6d7da-9366-41dd-b258-471a7c70b8ed',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Link (modular_content)
             */
            link: {
                codename: 'link',
                id: 'afb3f70a-6c20-4296-95b5-ea09dcde8365',
                externalId: undefined,
                name: 'Link',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '661c9e7e-c42c-4510-bbf5-844b9f8da934',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Banner   Links
     */
    banner___links: {
        codename: 'banner___links',
        id: '79349785-3c5e-4815-9261-961f9fad4811',
        externalId: undefined,
        name: '🧩 Banner - Links',
        elements: {
            /**
             * Background (multiple_choice)
             */
            background: {
                codename: 'background',
                id: '89737b46-41fd-4396-9d6f-a16919d8dcd0',
                externalId: undefined,
                name: 'Background',
                required: false,
                type: 'multiple_choice',
                options: {
                    black: {
                        name: 'Black',
                        id: '5e0e0bb1-4725-4c61-b19f-70bfae1f14ee',
                        codename: 'black',
                        externalId: undefined
                    },
                    none: {
                        name: 'None',
                        id: '4a7a1362-9768-49db-b26d-ef9187ab3a66',
                        codename: 'none',
                        externalId: undefined
                    }
                }
            },

            /**
             * Tiles (modular_content)
             */
            tiles: {
                codename: 'tiles',
                id: '41097fd8-317e-4257-bf22-f578fafedce8',
                externalId: undefined,
                name: 'Tiles',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '51c36904-2d5e-4e54-8f4a-e8839076046c',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Banner   Partners
     */
    banner___partners: {
        codename: 'banner___partners',
        id: '58517db9-ad77-459d-b4b8-890b755673ee',
        externalId: undefined,
        name: '🧩 Banner - Partners',
        elements: {
            /**
             * Background (multiple_choice)
             */
            background: {
                codename: 'background',
                id: 'e499e1bf-5265-41ad-8234-9079da23d334',
                externalId: undefined,
                name: 'Background',
                required: false,
                type: 'multiple_choice',
                options: {
                    light: {
                        name: 'Light',
                        id: '84460360-7dec-49fd-9a60-6577290b6ded',
                        codename: 'light',
                        externalId: undefined
                    },
                    dark: {
                        name: 'Dark',
                        id: 'b6e5679e-87fb-4f2b-99e7-53a490e0cdbf',
                        codename: 'dark',
                        externalId: undefined
                    }
                }
            },

            /**
             * Partners (modular_content)
             */
            partners: {
                codename: 'partners',
                id: '32ef23bf-890c-40a6-a5a1-bbf54c4728b1',
                externalId: undefined,
                name: 'Partners',
                required: false,
                type: 'modular_content'
            },

            /**
             * Sub title (text)
             */
            sub_title: {
                codename: 'sub_title',
                id: '9bca2dfa-4396-4d1d-b70b-0b96126d824f',
                externalId: undefined,
                name: 'Sub-title',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'cc9d10f3-d71c-4a0c-b423-796ed823a57b',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Banner   Quote
     */
    banner___quote: {
        codename: 'banner___quote',
        id: '38849a3b-ab26-46d0-9f76-6651ce278213',
        externalId: undefined,
        name: '🧩 Banner - Quote',
        elements: {
            /**
             * CTA (modular_content)
             */
            cta: {
                codename: 'cta',
                id: 'e6b8f07a-3c31-4349-8860-ae89ce8d9c7e',
                externalId: undefined,
                name: 'CTA',
                required: false,
                type: 'modular_content'
            },

            /**
             * Quote (rich_text)
             */
            quote: {
                codename: 'quote',
                id: '6d7e5577-13b3-4b69-b625-ee38e8a76ae2',
                externalId: undefined,
                name: 'Quote',
                required: false,
                type: 'rich_text'
            },

            /**
             * Quote Author (text)
             */
            quote_author: {
                codename: 'quote_author',
                id: '5c473efd-e11d-4bd8-9385-67d86ed87d58',
                externalId: undefined,
                name: 'Quote Author',
                required: false,
                type: 'text'
            },

            /**
             * Style (multiple_choice)
             */
            style: {
                codename: 'style',
                id: 'ba7742ee-512d-4cd6-b2b5-3067c0d76f00',
                externalId: undefined,
                name: 'Style',
                required: false,
                type: 'multiple_choice',
                options: {
                    n50_50: {
                        name: '50 50',
                        id: 'c638840b-f0a0-41b8-ac66-217416bca8ab',
                        codename: 'n50_50',
                        externalId: undefined
                    },
                    full_width: {
                        name: 'Full Width',
                        id: 'e4b35ad4-af9f-4f71-900c-177565700eb7',
                        codename: 'full_width',
                        externalId: undefined
                    }
                }
            },

            /**
             * Sub title (text)
             */
            sub_title: {
                codename: 'sub_title',
                id: 'e7fd4566-e922-43ba-bbf4-61191e5c4322',
                externalId: undefined,
                name: 'Sub-title',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '4ee03e3e-f7f8-4df0-82f9-fab9da780a6e',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Banner   Title   Text
     */
    banner___title___text: {
        codename: 'banner___title___text',
        id: 'd5f1bc7a-181a-426f-9c6b-f1423c2a9937',
        externalId: undefined,
        name: '🧩 Banner - Title + Text',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '6ffe8bb2-f0c2-4953-a962-c4f02431b5b0',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title  H1  (text)
             */
            title: {
                codename: 'title',
                id: '8f2e5d76-b4f8-4842-aa7b-f9c1a72439b0',
                externalId: undefined,
                name: 'Title (H1)',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Black Info Block
     */
    black_info_block: {
        codename: 'black_info_block',
        id: '7b872df7-018c-45f8-8fe3-f7d628b6d900',
        externalId: undefined,
        name: '🧩 Black Info Block',
        elements: {
            /**
             * 50 50 Image (asset)
             */
            n50_50_image: {
                codename: 'n50_50_image',
                id: '1c795431-af26-4ec0-bf42-0b83c7037507',
                externalId: undefined,
                name: '50/50 Image',
                required: false,
                type: 'asset'
            },

            /**
             * 50 50 Text (rich_text)
             */
            n50_50_text: {
                codename: 'n50_50_text',
                id: '240b251a-d5b3-43b2-a86a-f612ef1f1a45',
                externalId: undefined,
                name: '50/50 Text',
                required: false,
                type: 'rich_text'
            },

            /**
             * Full Width Text (rich_text)
             */
            full_width_text: {
                codename: 'full_width_text',
                id: '384bce7b-42c9-48c9-aa36-5b9a236dfa84',
                externalId: undefined,
                name: 'Full Width Text',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'f7faeb87-986f-4b08-bc31-278e58f95bd7',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Breadcrumb
     */
    _breadcrumb: {
        codename: '_breadcrumb',
        id: '5578aeb5-c992-4980-9c3f-70f9647b87f3',
        externalId: undefined,
        name: '🧩 Breadcrumb',
        elements: {
            /**
             * Content (text)
             */
            content: {
                codename: 'content',
                id: '210b931c-a31c-4161-9f3e-39e66a1373dd',
                externalId: undefined,
                name: 'Content',
                required: true,
                type: 'text'
            },

            /**
             * Path (text)
             */
            path: {
                codename: 'path',
                id: '78970730-7e34-4929-b19c-6fdb9625b3fe',
                externalId: undefined,
                name: 'Path',
                required: true,
                type: 'text'
            }
        }
    },

    /**
     *    Case Study   Quote
     */
    case_study___quote: {
        codename: 'case_study___quote',
        id: '17548892-5dfa-4f0a-b215-ab3edf12b39b',
        externalId: undefined,
        name: '🧩 Case Study - Quote',
        elements: {
            /**
             * Author (text)
             */
            author: {
                codename: 'author',
                id: 'df6aed5a-38e3-45ee-81f9-dbdd839070b8',
                externalId: undefined,
                name: 'Author',
                required: false,
                type: 'text'
            },

            /**
             * Quote (text)
             */
            quote: {
                codename: 'quote',
                id: 'c81b8e43-a617-481f-9e73-9fe36ddc55f8',
                externalId: undefined,
                name: 'Quote',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '71a6c753-e99f-4065-9494-a1b4583c0d4b',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Contact Us Form
     */
    contact_us_form: {
        codename: 'contact_us_form',
        id: '89cfe05a-614c-4752-8d15-f530362a8469',
        externalId: undefined,
        name: '🧩 Contact Us Form',
        elements: {}
    },

    /**
     *    Fellows List
     */
    fellows_list: {
        codename: 'fellows_list',
        id: '93be1b61-bd41-42a9-b483-5b8f0ff78009',
        externalId: undefined,
        name: '🧩 Fellows List',
        elements: {
            /**
             * Closing Text (rich_text)
             */
            closing_text: {
                codename: 'closing_text',
                id: '3723a9a6-488f-4774-9cbb-c00439436c2d',
                externalId: undefined,
                name: 'Closing Text',
                required: false,
                type: 'rich_text'
            },

            /**
             * Fellows (modular_content)
             */
            fellows: {
                codename: 'fellows',
                id: '83e14360-c0ce-4dfd-b645-a1df70d42273',
                externalId: undefined,
                name: 'Fellows',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'f20673ed-1a1d-426d-903b-d25b7b186220',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Hero
     */
    hero: {
        codename: 'hero',
        id: '0ab7970b-ca94-42f4-ad25-7b36687893b9',
        externalId: undefined,
        name: '🧩 Hero',
        elements: {
            /**
             * CTA (modular_content)
             */
            cta: {
                codename: 'cta',
                id: 'd4e62169-e3f8-4632-833b-efebcf77d734',
                externalId: undefined,
                name: 'CTA',
                required: false,
                type: 'modular_content'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '8655edee-6fa1-4cba-87bc-adcc3d4fa0ec',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Style (multiple_choice)
             */
            style: {
                codename: 'style',
                id: '9191d926-eff0-490c-bf4a-56569534d576',
                externalId: undefined,
                name: 'Style',
                required: false,
                type: 'multiple_choice',
                options: {
                    home: {
                        name: 'Home',
                        id: 'c2045252-909c-4693-9b35-7dcd0c1e4955',
                        codename: 'home',
                        externalId: undefined
                    },
                    full_screen: {
                        name: 'Full Screen',
                        id: '51fbd43a-703f-42ea-bc29-cfeb4d66fed1',
                        codename: 'full_screen',
                        externalId: undefined
                    },
                    full_screen_image_left: {
                        name: 'Full Screen Image Left',
                        id: '17bb3e88-61c9-4e31-84d3-d85db133bce4',
                        codename: 'full_screen_image_left',
                        externalId: undefined
                    },
                    overlay___light: {
                        name: 'Overlay   Light',
                        id: 'aecfcd41-423d-4132-a0df-e99ef30d2722',
                        codename: 'overlay___light',
                        externalId: undefined
                    },
                    overlay___dark: {
                        name: 'Overlay   Dark',
                        id: 'cbbf4365-b313-480a-8a44-1eca0dede140',
                        codename: 'overlay___dark',
                        externalId: undefined
                    },
                    split: {
                        name: 'Split',
                        id: 'c78b96bd-c510-4d46-8d76-1b157b2b4fd0',
                        codename: 'split',
                        externalId: undefined
                    },
                    simple: {
                        name: 'Simple',
                        id: '6ec01ffd-4d1d-4152-959b-786193544cf5',
                        codename: 'simple',
                        externalId: undefined
                    }
                }
            },

            /**
             * Sub Title (rich_text)
             */
            sub_title: {
                codename: 'sub_title',
                id: '829d36ba-a3e7-499d-8937-b10779ab2f4f',
                externalId: undefined,
                name: 'Sub-Title',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'c9feccf7-af34-47c3-842a-a0ee3ef30046',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    News List
     */
    news_list: {
        codename: 'news_list',
        id: 'ed976d93-6b57-4714-95c9-b43c4a629013',
        externalId: undefined,
        name: '🧩 News List',
        elements: {
            /**
             * News Type (taxonomy)
             *
             * If no options are selected then all items will show in the list.
             */
            list_types: {
                codename: 'list_types',
                id: 'c24e81bb-ba00-4f31-bfb3-9aac8dc96e84',
                externalId: undefined,
                name: 'List Types',
                required: false,
                type: 'taxonomy'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'c75006b7-c87e-4b2d-b7d0-6eecfeadef71',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Newsletter List
     */
    _news_list__copy_: {
        codename: '_news_list__copy_',
        id: 'aac00ba3-9e9f-4848-8a2f-6c1097d323ac',
        externalId: undefined,
        name: '🧩 Newsletter List',
        elements: {
            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '514dec9c-5462-4d2a-951c-1e218f90b829',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Newsletter Subscription
     */
    newsletter_subscription: {
        codename: 'newsletter_subscription',
        id: 'b5496e30-0e7d-41b4-864e-939a144addb7',
        externalId: undefined,
        name: '🧩 Newsletter Subscription',
        elements: {}
    },

    /**
     *    Team List
     */
    team_list: {
        codename: 'team_list',
        id: '25335ed7-304a-4be8-b2df-26a0c083673c',
        externalId: undefined,
        name: '🧩 Team List',
        elements: {
            /**
             * Advisory Board (modular_content)
             */
            advisory_board: {
                codename: 'advisory_board',
                id: '3e51296f-f1ff-43b5-9b37-442c2af542b8',
                externalId: undefined,
                name: 'Advisory Board',
                required: false,
                type: 'modular_content'
            },

            /**
             * Board of Directors (modular_content)
             */
            board_of_directors: {
                codename: 'board_of_directors',
                id: '56c3bf8a-04db-482c-ae79-74f20e9e157e',
                externalId: undefined,
                name: 'Board of Directors',
                required: false,
                type: 'modular_content'
            },

            /**
             * Global Technical Advisory Committee (modular_content)
             */
            global_technical_advisory_committee: {
                codename: 'global_technical_advisory_committee',
                id: '0d568f33-b5c7-40b2-84be-d7665bc33131',
                externalId: undefined,
                name: 'Global Technical Advisory Committee',
                required: false,
                type: 'modular_content'
            },

            /**
             * Our Team (modular_content)
             */
            team_members: {
                codename: 'team_members',
                id: '2698cf9a-f3c4-4cd2-bd4c-9102136f589d',
                externalId: undefined,
                name: 'Our Team',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '017ee02b-be10-48a4-9c5e-f6c6c73868dc',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Team Member List
     */
    team_member_list: {
        codename: 'team_member_list',
        id: 'e190c090-3cde-42d7-a69b-7e4897e9aadc',
        externalId: undefined,
        name: '🧩 Team Member List',
        elements: {
            /**
             * Team Members (modular_content)
             */
            team_members: {
                codename: 'team_members',
                id: '3a5e53ad-612a-478e-96b6-0581e3931368',
                externalId: undefined,
                name: 'Team Members',
                required: true,
                type: 'modular_content'
            }
        }
    },

    /**
     *    Video
     */
    video: {
        codename: 'video',
        id: '0db20a27-17c3-43f4-90c7-a4d1a608d646',
        externalId: undefined,
        name: '🧩 Video',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '1fd644bd-821e-447c-82ff-935d50753d41',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '27be8617-e215-4d78-a418-657c66faac46',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Video Embed Code (text)
             */
            video_embed_code: {
                codename: 'video_embed_code',
                id: 'c395611a-bb27-4cf4-a10c-75a95ae35e66',
                externalId: undefined,
                name: 'Video Embed Code',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * Form Text Field
     */
    form_text_field: {
        codename: 'form_text_field',
        id: 'd241706d-d1d9-4de9-ba2b-3fa97bb992a9',
        externalId: undefined,
        name: 'Form Text Field',
        elements: {
            /**
             * Content (text)
             */
            content: {
                codename: 'content',
                id: '310d9744-71c3-4dcc-b779-f1d2a301b52c',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     * NTK App Page
     */
    ntk_app_page: {
        codename: 'ntk_app_page',
        id: 'dd78d198-39cf-4b74-876c-625977245cd4',
        externalId: undefined,
        name: 'NTK App Page',
        elements: {
            /**
             * App 50 50 Blocks (modular_content)
             */
            app_50_50_blocks: {
                codename: 'app_50_50_blocks',
                id: 'dadaf42f-284c-4a7d-a3d4-e8f5839b473b',
                externalId: undefined,
                name: 'App 50/50 Blocks',
                required: false,
                type: 'modular_content'
            },

            /**
             * Black Info Block (modular_content)
             */
            black_info_block: {
                codename: 'black_info_block',
                id: '7f215e05-be68-4355-9af1-a6a17f05b69c',
                externalId: undefined,
                name: 'Black Info Block',
                required: false,
                type: 'modular_content'
            },

            /**
             * Collaboration 50 50 Blocks (modular_content)
             */
            collaboration_50_50_blocks: {
                codename: 'collaboration_50_50_blocks',
                id: 'eaded86e-578a-4426-90d2-6244b59f2fba',
                externalId: undefined,
                name: 'Collaboration 50/50 Blocks',
                required: false,
                type: 'modular_content'
            },

            /**
             * Collaboration Title (text)
             */
            collaboration_title: {
                codename: 'collaboration_title',
                id: 'b38ac1c8-9aa7-4ef6-92f9-9e2fa2140dbc',
                externalId: undefined,
                name: 'Collaboration Title',
                required: false,
                type: 'text'
            },

            /**
             * Hero (modular_content)
             */
            hero: {
                codename: 'hero',
                id: 'a8c8e257-46ee-43f2-b595-6a038087cc3c',
                externalId: undefined,
                name: 'Hero',
                required: false,
                type: 'modular_content'
            },

            /**
             * Meta descriptions (text)
             */
            seo_metadata__meta_descriptions: {
                codename: 'seo_metadata__meta_descriptions',
                id: '27d69af3-0694-42e5-b9b5-11de3276b023',
                externalId: undefined,
                name: 'Meta descriptions',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '7df6f45d-1f84-4916-805e-d4b919ce7fae',
                externalId: undefined,
                name: 'Meta keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Partners Banner (modular_content)
             */
            partners_banner: {
                codename: 'partners_banner',
                id: '1d1a0a3f-84e5-4fb5-9861-1aeefe2384c1',
                externalId: undefined,
                name: 'Partners Banner',
                required: false,
                type: 'modular_content'
            },

            /**
             * Roche Trademarks (modular_content)
             */
            roche_trademarks: {
                codename: 'roche_trademarks',
                id: 'f2d2ba6f-166c-4366-885e-d7ba8162fe2c',
                externalId: undefined,
                name: 'Roche Trademarks',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '900f4e73-bf42-411b-9e61-bc000d6c0dca',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Tutorials Title (text)
             */
            tutorials_title: {
                codename: 'tutorials_title',
                id: '28a712cd-9b3c-40c0-944c-753892cdc058',
                externalId: undefined,
                name: 'Tutorials Title',
                required: false,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'd7cae35a-7f51-425d-adc9-afe5ca65b79e',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'url_slug'
            },

            /**
             * Video (modular_content)
             */
            video: {
                codename: 'video',
                id: 'cd6b58de-528c-4a73-af55-4c298400f7ee',
                externalId: undefined,
                name: 'Video',
                required: false,
                type: 'modular_content'
            },

            /**
             * Video 50 50 Blocks (modular_content)
             */
            video_50_50_video_blocks: {
                codename: 'video_50_50_video_blocks',
                id: 'b05cf282-0c50-4c4f-add2-d3f17c15647f',
                externalId: undefined,
                name: 'Video 50/50 Blocks',
                required: false,
                type: 'modular_content'
            }
        }
    }
} as const;
