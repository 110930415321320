<template>
    <span :id="id" class="anchor-tag__icon">
        <NuxtLink v-if="showIcon" class="anchor-link docon docon-link" :to="`#${id}`"></NuxtLink>
    </span>
</template>
<script setup lang="ts">
import type { AnchorTagModel } from '~/models';
const props = defineProps<{ model: AnchorTagModel | null }>();
const id = props.model?.elements.id.value;
const showIcon = props.model?.elements.showTagIcon.value[0].codename === 'yes';
</script>
<style lang="scss" scoped>
.docon-link::before {
    content: "";
}
</style>