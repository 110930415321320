<template>
	<div class="video-block promo container container--50-50" :class="styleClass" v-bind:[attributes.componentId]="type?.id">
		<div class="promo__col container__col container__col--50">
			<div class="promo__text">
				<h2 class="promo__title">{{ model.elements.title.value }}</h2>
				<div class="rich-text promo__description">
					<RichText :value="model.elements.content.value" />
				</div>				
				<CallToAction v-for="(cta) in model?.elements.cta.value"
					class="router-link-active router-link-exact-active promo__button btn btn--primary btn--black-hover"
					:model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
			</div>
		</div>
		<div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
			<section class="video-block">
				<figure class="video-block__media js-video-player__container">
					<div class="video-block__media-wrapper">
						<span v-html="model.elements.videoEmbedCode.value"></span>
					</div>
				</figure>
			</section>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.n50_50_video_block;
import type { CallToActionModel, N5050VideoBlockModel } from '~/models';
const props = defineProps<{ model: N5050VideoBlockModel }>();
const pageStore = usePageStore();

const styleClass = computed(() => {
	let className = '';
	if (props.model.elements.style.value.length > 0) {
		const styleCodename = props.model.elements.style.value[0].codename;
		switch (styleCodename) {
			case 'image_fill':
				className = 'style-image-fill';
				break;
			case 'image_with_border':
				className = 'style-image-with-border';
				break
			case 'transparent':
				className = 'style-transparent';
				break
			default:
				break;
		}

		if (props.model.elements.imageAlignment.value.length > 0) {
			const alignmentCodename = props.model.elements.imageAlignment.value[0].codename;
			switch (alignmentCodename) {
				case 'left':
					className += ' promo--left';
					break;
				case 'right':
					className += ' promo--right';
					break;
				default:
					className += ' promo--left';
					break;
			}
		}
	}
	return className;
});

</script>

<style lang="scss" scoped>
@use "~/assets/scss/promo.scss";
@use "~/assets/scss/n5050.scss";

.video-block {
	margin-bottom: 10px;
}
</style>