<template>
    <section class="container container--100">
        <div class="back-link">
            <NuxtLink :to="'/news'" class="back-link__link">
                Back to News
            </NuxtLink>
        </div>
    </section>

    <section class="container container--70-30 news-item" v-bind:[attributes.componentId]="typeId">

        <div class="container__col container__col--70">
            <div class="page-header container">
                <div class="page-header__eyebrow" v-bind:[attributes.elementCodename]="type.news_type.codename">{{ page?.elements?.newsType?.value[0]?.name }}</div>
                <h1 class="page-header__title" v-bind:[attributes.elementCodename]="type.title.codename">{{ page?.elements?.title?.value }}</h1>
                <div class="page-header__subtitle" v-bind:[attributes.elementCodename]="type.sub_title.codename">{{ page?.elements?.subTitle?.value }}</div>
            </div>
            <div class="rich-text" v-bind:[attributes.elementCodename]="type.content.codename">
                <RichText :value="page?.elements?.content?.value"></RichText>
            </div>
        </div>

    </section>
</template>
<script lang="ts" setup >
import type { NewsItemModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.news_item.elements;
const typeId = contentTypes.news_item.id;
const pageStore = usePageStore();

const page = pageStore.page as NewsItemModel;



</script>

<style lang="scss" scoped>
@use "~/assets/scss/page-header.scss";
</style>