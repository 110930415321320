<template>
    <div class="who-we-are" v-bind:[attributes.itemId]="model?.system.id">
        <div class="team-listing">
            <section class="who-we-are component">
                <div class="who-we-are__collection">
                    <h2 class="who-we-are__title" v-bind:[attributes.elementCodename]="type.team_members.name">{{
                        data.elements.teamMembers.name }}</h2>
                    <div class="who-we-are__list">
                        <div v-for="member in teamMembersList" :key="member.system.id" class="who-we-are__item"
                            v-bind:[attributes.itemId]="member?.system.id">
                            <NuxtLink :to="'/about/our-team-bios#' + member.system.codename" class="who-we-are__link">
                                <span class="who-we-are__name"
                                    v-bind:[attributes.elementCodename]="memberType.name.codename">{{
                                        member.elements.name.value }}</span>
                                <span class="who-we-are__association"
                                    v-bind:[attributes.elementCodename]="memberType.title.codename">{{
                                        member.elements.title.value }}</span>
                            </NuxtLink>
                        </div>
                    </div>
                </div>
            </section>
            <section class="who-we-are component" v-bind:[attributes.elementCodename]="type.advisory_board">
                <div class="who-we-are__collection">
                    <h2 class="who-we-are__title">{{ data.elements.advisoryBoard.name }}</h2>
                    <div class="who-we-are__list">
                        <div v-for="member in advisoryBoardList" :key="member.system.name" class="who-we-are__item">
                            <NuxtLink :to="'/about/board#' + member.system.codename" class="who-we-are__link">

                                <span class="who-we-are__name">
                                    {{ member.elements.memberName.value }}
                                </span>
                                <span class="who-we-are__association" v-for="codename in member.elements.partner.value">
                                    {{ getPartnerName(codename) }}
                                </span>

                            </NuxtLink>
                        </div>
                    </div>
                </div>
            </section>
            <section class="who-we-are component"
                v-bind:[attributes.elementCodename]="type.global_technical_advisory_committee.codename">
                <div class="who-we-are__collection">
                    <h2 class="who-we-are__title">{{ globalCommitteeMembers.name }}</h2>
                    <div class="who-we-are__list">
                        <div v-for="member in globalCommitteeMembersList" :key="member.elements.titleTextTitle.value"
                            class="who-we-are__item">
                            <div class="who-we-are__committee-member">
                                <span class="who-we-are__name">
                                    {{ member.elements.titleTextTitle.value }}
                                </span>
                                <span class="who-we-are__association">
                                    {{ member.elements.titleTextText.value }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup lang="ts">

import type { AdvisoryBoardMemberModel, PartnerModel, GlobalTechnicalAdvisoryCommitteeMemberModel, TeamListModel, TeamMemberModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.team_list.elements;
const memberType = contentTypes.team_member.elements;
const page = usePageStore();

const props = defineProps<{ model: TeamListModel }>();
const data = props.model;
const globalCommitteeMembers = data.elements.globalTechnicalAdvisoryCommittee
const teamMembersList = data.elements.teamMembers.value.map(x => page.getLinkedItem<TeamMemberModel>(x));
const advisoryBoardList = data.elements.advisoryBoard.value.map(x => page.getLinkedItem<AdvisoryBoardMemberModel>(x));
const globalCommitteeMembersList = data.elements.globalTechnicalAdvisoryCommittee.value.map(x => page.getLinkedItem<GlobalTechnicalAdvisoryCommitteeMemberModel>(x));

function getPartnerName(codename: string) {
    const partner = page.getLinkedItem<PartnerModel>(codename);
    return partner?.elements.name.value;
}
</script>

<style lang="scss" scoped>
@use "~/assets/scss/whoweare.scss";

.rich-text {
    max-width: 1850px;
}

.rich-text a {
    border-bottom: none;
}</style>