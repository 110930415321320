<template>
    <section class="container container--100">
        <div class="page-header container">
            <div class="page-header__eyebrow"></div>
            <h1 class="page-header__title">Lost?</h1>
            <div class="page-header__subtitle">We can't seem to find what you're looking for</div>
        </div>

    </section>
    <section class="container container--100">
        <div class="rich-text">
            <p>Please <a href="/">Contact U</a>s to report this page.</p>
            <p>Click <a href="/">here </a>to go back to the homepage.</p>
        </div>

    </section>
</template>
