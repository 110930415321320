<template>
    <section class="container container--bleed " style="" v-bind:[attributes.componentId]="typeId">
        <section class="container container--100">
            <div class="horizontal-card container horizontal-card--three">
                <h2 class="horizontal-card__title" v-bind:[attributes.elementCodename]="type.title.codename">{{ model?.elements.title.value }}</h2>
                <div class="horizontal-card__container">
                    <template v-if="hasTiles">
                        <Tile v-for="(item) in model?.elements.tiles.value"
                            :model="pageStore.getLinkedItem<TileModel>(item)" />
                    </template>
                </div>
            </div>
        </section>
    </section>
</template>

<script setup lang="ts">
import type { BannerLinksModel, TileModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.banner___links.elements;
const typeId = contentTypes.banner___links.id;
const pageStore = usePageStore()
const props = defineProps<{ model: BannerLinksModel | null }>();
const hasTiles = props.model?.elements?.tiles?.value.length ?? 0 > 0;
</script>
<style lang="scss" scoped>
@use "~/assets/scss/horizontal_card.scss";
@use "~/assets/scss/a.scss";
</style>