<template>
    <section class="container container--100" v-bind:[attributes.componentId]="typeId">

        <section class="component dynamic-list-mount js-dynamic-list">
            <div class="dynamic-list">
                <div class="search__results container container--100">
                    <section class="container manual-content-listing">
                        <ul class="manual-content-listing__items dynamic-list__list">
                            <li class="dynamic-list__item" v-for="(item) in items">
                                <div class="dynamic-list__card" tabindex="-1">
                                    <figure class="dynamic-list__card-media">
                                        <NuxtLink :to="siteStore.getUrlByCodename(item.system.codename)"
                                            :title="item.elements.title.value">
                                            <NuxtImg provider="kontentAiProvider"
                                                v-if="item?.elements?.image?.value[0]?.url"
                                                :src="item.elements.image.value[0].url"></NuxtImg>
                                        </NuxtLink>
                                    </figure>
                                    <div class="dynamic-list__card-content">
                                        <div class="dynamic-list__card-meta">
                                            <div class="dynamic-list__card-type">{{
        item?.elements?.newsType?.value[0]?.name }}</div>
                                            <div class="dynamic-list__card-date" v-if="item.elements.date.value">{{
        useDateFormat(item.elements.date.value, 'MMMM YYYY').value.toUpperCase()
    }}
                                            </div>
                                        </div>
                                        <div class="dynamic-list__card-title">
                                            <NuxtLink :to="siteStore.getUrlByCodename(item.system.codename)"
                                                :title="item.elements.title.value">
                                                {{ item.elements.title.value }}
                                            </NuxtLink>
                                        </div>
                                        <div class="dynamic-list__card-summary">{{ item.elements.subTitle.value }}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </section>
                </div>
                <div class="search__pagination search__pagination--centered">
                    <div class="search__more">
                        <div class="search-results__load-more"><button class="btn btn--primary btn--black-hover"
                                @click="loadMore" v-if="inScreenCount !== items.length" type="button">Load
                                More</button></div>
                    </div>
                </div>
            </div>
        </section>

    </section>
</template>

<script setup lang="ts">
import type { NewsItemModel, NewsListModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const typeId = contentTypes.news_item.id;
const itemList: Ref<NewsItemModel[]> = ref([]);
const loadIndex = ref(0);
const inScreenCount = ref(0);
const siteStore = useSiteStore()

const props = defineProps<{ model: NewsListModel }>();

const allowLoadMore = ref(false);
const items: Ref<NewsItemModel[]> = ref([]);

await loadMore();

async function loadMore() {

    loadIndex.value++;
    const { data } = await useFetch(`/api/news?page=${loadIndex.value}`);

    if (data) {
        const newsList = (data as any).value?.data?.items as NewsItemModel[];
        console.log(newsList);
        if (newsList) {
            newsList.forEach(i => {
                items.value.push(i);
            });
        }
    }

    verifyIfTheresMoreContent();
}

async function verifyIfTheresMoreContent() {

    const { data } = await useFetch(`/api/news?page=${loadIndex.value + 1}`);

    if (data) {
        const newsList = (data as any)?.value?.data?.items as NewsItemModel[];

        if (newsList) {
            allowLoadMore.value = newsList.length > 0;
            return;
        }

        allowLoadMore.value = false;
    }
}


async function loadMoreIndexed() {

    const limit = (inScreenCount.value + 2) > items.value.length - 1 ? items.value.length - 1 : inScreenCount.value + 2

    for (let i = inScreenCount.value; i <= limit; i++) {
        itemList.value.push(items.value[i]);
        inScreenCount.value++;
    }


}

</script>

<style lang="scss" scoped>
@use "~/assets/scss/back-link.scss";
@use "~/assets/scss/dynamic-list.scss";
@use "~/assets/scss/dynamic-card.scss";

.dynamic-list {
    padding: 0px;
}

.workbench-hero__text-container {
    padding-bottom: 200px;
}
</style>
