<template>
    <section class="container container--bleed " v-if="getStyleEnumByModel(model) == StyleTypeEnum.None"
        v-bind:[attributes.componentId]="typeId">
        <div class="promo promo--right container container--50-50">
            <div class="promo__col container__col container__col--50">
                <div class="promo__text">
                    <h1 v-bind:[attributes.elementCodename]="contentTypes.hero.elements.title.codename"
                        class="promo__title">{{
                            model?.elements?.title?.value }}</h1>
                    <div v-bind:[attributes.elementCodename]="contentTypes.hero.elements.sub_title.codename"
                        v-if="!isEmpty(model?.elements.subTitle.value)" class="rich-text promo__description">
                        <RichText :value="model?.elements?.subTitle.value" />
                    </div>
                    <CallToAction v-for="(cta) in model?.elements.cta.value"
                        :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                </div>
            </div>
            <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                <picture v-bind:[attributes.elementCodename]="contentTypes.hero.elements.image.codename"
                    class="promo__picture">
                    <NuxtImg provider="kontentAiProvider"
                        v-bind:[attributes.elementCodename]="contentTypes.hero.elements.image.codename"
                        v-for="(item, index) in model?.elements?.image?.value" :src="item?.url"></NuxtImg>
                </picture>
            </div>
        </div>

    </section>

    <section class="container container--bleed " v-if="getStyleEnumByModel(model) == StyleTypeEnum.Home"
        v-bind:[attributes.componentId]="typeId">
        <div class="promo promo--right container container--50-50">
            <div class="promo__col container__col container__col--50">
                <div class="promo__text">
                    <h1 class="promo__title" v-bind:[attributes.elementCodename]="contentTypes.hero.elements.title.codename">{{ model?.elements?.title?.value }}</h1>
                    <div v-bind:[attributes.elementCodename]="contentTypes.hero.elements.sub_title.codename"
                        v-if="!isEmpty(model?.elements.subTitle.value)" class="rich-text promo__description">
                        <RichText :value="model?.elements?.subTitle.value" />
                    </div>
                    <CallToAction v-for="(cta) in model?.elements.cta.value"
                        :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                </div>
            </div>
            <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                <picture v-bind:[attributes.elementCodename]="contentTypes.hero.elements.image.codename"
                    class="promo__picture">
                    <NuxtImg provider="kontentAiProvider" v-for="(item, index) in model?.elements?.image?.value"
                        :src="item?.url">
                    </NuxtImg>
                </picture>
            </div>
        </div>

    </section>

    <section class="container container--bleed hero-fs" v-if="getStyleEnumByModel(model) == StyleTypeEnum.FullScreen"
        v-bind:[attributes.componentId]="typeId">
        <div class="promo promo--right container container--50-50">
            <div class="promo__col container__col container__col--50">
                <div class="promo__text">
                    <h1 class="promo__title" v-bind:[attributes.elementCodename]="contentTypes.hero.elements.title.codename">{{ model?.elements?.title?.value }}</h1>
                    <div v-bind:[attributes.elementCodename]="contentTypes.hero.elements.sub_title.codename"
                        v-if="!isEmpty(model?.elements.subTitle.value)" class="rich-text promo__description">
                        <RichText :value="model?.elements?.subTitle.value" />
                    </div>
                    <CallToAction v-for="(cta) in model?.elements.cta.value"
                        :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                </div>
            </div>
            <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                <picture v-bind:[attributes.elementCodename]="contentTypes.hero.elements.image.codename"
                    class="promo__picture">
                    <NuxtImg provider="kontentAiProvider" v-for="(item, index) in model?.elements?.image?.value"
                        :src="item?.url">
                    </NuxtImg>
                </picture>
            </div>
        </div>
    </section>

    <section class="container container--bleed hero-fs image-left"
        v-if="getStyleEnumByModel(model) == StyleTypeEnum.FullScreenImageLeft"
        v-bind:[attributes.componentId]="typeId" >
        <div class="promo promo--right container container--50-50">
            <div class="promo__col container__col container__col--50">
                <div class="promo__text">
                    <h1 class="promo__title" v-bind:[attributes.elementCodename]="contentTypes.hero.elements.title.codename">{{ model?.elements?.title?.value }}</h1>
                    <div v-bind:[attributes.elementCodename]="contentTypes.hero.elements.sub_title.codename"
                        v-if="!isEmpty(model?.elements.subTitle.value)" class="rich-text promo__description">
                        <RichText :value="model?.elements?.subTitle.value" />
                    </div>
                    <CallToAction v-for="(cta) in model?.elements.cta.value"
                        :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                </div>
            </div>
            <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                <picture v-bind:[attributes.elementCodename]="contentTypes.hero.elements.image.codename"
                    class="promo__picture">
                    <NuxtImg provider="kontentAiProvider" v-for="(item, index) in model?.elements?.image?.value"
                        :src="item?.url">
                    </NuxtImg>
                </picture>
            </div>
        </div>
    </section>

    <section class="container container--bleed workbench-hero__light" v-bind:[attributes.componentId]="typeId"
        v-if="getStyleEnumByModel(model) == StyleTypeEnum.OverlayLight">
        <section class="workbench-hero container container--bleed">
            <div class="workbench-hero__background">
                <div class="workbench-hero__text-container">
                    <h1 class="workbench-hero__heading" v-bind:[attributes.elementCodename]="contentTypes.hero.elements.title.codename">{{ model?.elements?.title?.value }}</h1>
                    <div v-bind:[attributes.elementCodename]="contentTypes.hero.elements.sub_title.codename"
                        v-if="!isEmpty(model?.elements.subTitle.value)" class="workbench-hero__description">
                        <RichText :value="model?.elements?.subTitle.value" />
                    </div>
                    <div class="workbench-hero__buttons">
                        <CallToAction v-for="(cta) in model?.elements.cta.value"
                            class="promo__button btn btn--primary btn--black-hover"
                            :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                    </div>
                </div>
            </div>
            <div class="workbench-hero__image">

            </div>
        </section>

    </section>


    <section class="container container--bleed workbench-hero__dark" v-bind:[attributes.componentId]="typeId"
        v-if="getStyleEnumByModel(model) == StyleTypeEnum.OverlayDark">
        <section class="workbench-hero container container--bleed ">
            <div class="workbench-hero__background">
                <div class="workbench-hero__text-container">
                    <h1 class="workbench-hero__heading" v-bind:[attributes.elementCodename]="contentTypes.hero.elements.title.codename">{{ model?.elements?.title?.value }}</h1>
                    <div v-bind:[attributes.elementCodename]="contentTypes.hero.elements.sub_title.codename"
                        v-if="!isEmpty(model?.elements.subTitle.value)" class="workbench-hero__description">
                        <RichText :value="model?.elements?.subTitle.value" />
                    </div>
                    <div class="workbench-hero__buttons">
                        <CallToAction v-for="(cta) in model?.elements.cta.value"
                            class="btn btn--primary workbench-hero__button"
                            :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                    </div>
                </div>
            </div>
            <div class="workbench-hero__image"></div>
        </section>
    </section>

    <section class="container container--bleed container--white-text hero-split"
        v-bind:[attributes.componentId]="typeId" v-if="getStyleEnumByModel(model) == StyleTypeEnum.Split">
        <div class="container container--100">
            <div class="promo promo--right container--50-50">
                <div class="promo__col container__col container__col--50">
                    <div class="promo__text">
                        <h1 class="promo__title" v-bind:[attributes.elementCodename]="contentTypes.hero.elements.title.codename">{{ model?.elements?.title?.value }}</h1>
                        <div v-bind:[attributes.elementCodename]="contentTypes.hero.elements.sub_title.codename"
                            v-if="!isEmpty(model?.elements.subTitle.value)" class="rich-text promo__description">
                            <RichText :value="model?.elements?.subTitle.value" />
                        </div>
                        <CallToAction v-for="(cta) in model?.elements.cta.value"
                            :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                    </div>
                </div>
                <div class="promo__col promo__col--image container__col container__col--50" aria-hidden="true">
                    <picture v-bind:[attributes.elementCodename]="contentTypes.hero.elements.image.codename"
                        class="promo__picture">
                        <NuxtImg provider="kontentAiProvider" v-for="(item, index) in model?.elements?.image?.value"
                            :src="item?.url">
                        </NuxtImg>
                    </picture>
                </div>
            </div>
        </div>
    </section>

    <section class="container container--bleed hero-simple" v-if="getStyleEnumByModel(model) == StyleTypeEnum.Simple"
        v-bind:[attributes.componentId]="typeId">
        <div class="text-banner container container--has-background">
            <div class="text-banner__container container container--100">
                <div class="container__col">
                    <h1 class="text-banner__title" v-bind:[attributes.elementCodename]="contentTypes.hero.elements.title.codename">{{ model?.elements?.title?.value }}</h1>
                    <div v-bind:[attributes.elementCodename]="contentTypes.hero.elements.sub_title.codename"
                        v-if="!isEmpty(model?.elements.subTitle.value)" class="text-banner__description">
                        <RichText :value="model?.elements?.subTitle.value" />
                    </div>
                    <div class="text-banner__buttons">
                        <CallToAction v-for="(cta) in model?.elements.cta.value"
                            class="btn btn--primary text-banner__button"
                            :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script setup lang="ts">
import { isEmpty } from '~/utils/kontent-helper'
import { contentTypes, type CallToActionModel, type HeroModel } from '~/models';
const pageStore = usePageStore()

const typeId = contentTypes.hero.id;
const attributes = GetKontentAttributes();
enum StyleTypeEnum {
    None,
    Home,
    FullScreen,
    FullScreenImageLeft,
    OverlayLight,
    OverlayDark,
    Split,
    Simple,
}

const styleMaps = new Map<string, StyleTypeEnum>([
    ['(None)', StyleTypeEnum.None],
    ['Home', StyleTypeEnum.Home],
    ['Full Screen', StyleTypeEnum.FullScreen],
    ['Full Screen Image Left', StyleTypeEnum.FullScreenImageLeft],
    ['Overlay - Light', StyleTypeEnum.OverlayLight],
    ['Overlay - Dark', StyleTypeEnum.OverlayDark],
    ['Simple', StyleTypeEnum.Simple],
    ['Split', StyleTypeEnum.Split],
]);

const props = defineProps<{ model: HeroModel | null }>();

//console.log('hero model', props.model);

function getStyleEnumByModel(model: HeroModel | null): StyleTypeEnum {

    if (!model) return StyleTypeEnum.None;
    if (model.elements.style && model.elements.style.value && model.elements.style.value.length > 0) {

        let style = model.elements.style.value[0].name;
        return styleMaps.get(style) ?? StyleTypeEnum.None;

    }

    return StyleTypeEnum.None;

}

</script>

<style lang="scss" scoped>@use "~/assets/scss/page-hero.scss";
@use "~/assets/scss/workbench-hero.scss";
@use "~/assets/scss/promo.scss";</style>