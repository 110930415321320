<template>
    <div class="who-we-are__item">
        <NuxtLink v-if="externalLink.length > 0" :to="externalLink"
            :target="externalLink.startsWith('http') ? '_blank' : '_self'" class="who-we-are__link">
            <span class="who-we-are__name" v-bind:[attributes.elementCodename]="memberType.name.codename">{{
            member.elements.name.value }}</span>
            <span class="who-we-are__association" v-bind:[attributes.elementCodename]="memberType.title.codename">{{
            member.elements.title.value }}</span>
        </NuxtLink>
        <div v-else class="who-we-are">
            <span class="who-we-are__name" v-bind:[attributes.elementCodename]="memberType.name.codename">{{
            member.elements.name.value }}</span>
            <span class="who-we-are__association" v-bind:[attributes.elementCodename]="memberType.title.codename">{{
            member.elements.title.value }}</span>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { TeamMemberModel } from '~/models';
const attributes = GetKontentAttributes();
import { contentTypes } from '~/models';

const props = defineProps<{ model: TeamMemberModel }>();
const member = props.model;
const memberType = contentTypes.team_member.elements;
const externalLink = ref('')
externalLink.value = member.elements.externalLink.value;
</script>