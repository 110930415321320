<template>
    <SeoMetaTags />
    <div :data-kontent-item-id="currentPage?.itemid">
        <component :is="activeComponent" />
    </div>
</template>
<script setup lang="ts">
import { ADWorkbench, DataSharingToolkitPage, HomePage, InteriorPage, NewsLettersPage, NotFound, NewsItem, NewsLetterItem, CaseStudiesListPage, CaseStudyItemPage, ApplyToSubmit, RichText, NTKAppPage } from '#components'
import { contentTypes } from '~/models';
useScriptTag("//cdn.flowplayer.com/releases/native/3/stable/flowplayer.min.js");

const route = useRoute()
const pageStore = usePageStore()
const siteStore = useSiteStore()
const currentPage = siteStore.getPage(route.path, route.query.itemid as string)

const bodyClass = ref<string>('')
const { data, error } = await useAsyncData(`page:${currentPage?.itemid}`, async () => {
    let page = null
    if (currentPage) {
        await pageStore.fetchData(currentPage.itemid)
        page = pageStore.page
    }

    return page
})
if (error.value) {
    console.error(error.value)
}




const activeComponent = resolveComponent(data.value)
useHead(() => {
    return {
        bodyAttrs: {
            class: bodyClass.value
        },
    }
})



function resolveComponent(data: any) {
    bodyClass.value = 'detail-page'
    if (!data) {
        return NotFound
    }

    switch (data?.system?.type) {
        case contentTypes.homepage.codename:
            bodyClass.value = ''
            return HomePage
        case contentTypes.page.codename:
            return InteriorPage
        case contentTypes.data_sharing_toolkit.codename:
            return DataSharingToolkitPage
        case contentTypes.adworkbench.codename:
            return ADWorkbench
        case contentTypes.news_item.codename:
            return NewsItem
        case contentTypes.news_letter_page.codename:
            return NewsLettersPage
        case contentTypes._newsletter_item.codename:
            return NewsLetterItem
        case contentTypes.case_study_list.codename:
            return CaseStudiesListPage
        case contentTypes.case_study.codename:
            return CaseStudyItemPage
        case contentTypes.apply_to_submit_data.codename:
            return ApplyToSubmit
        case contentTypes.ntk_app_page.codename:
            return NTKAppPage
        default:
            return NotFound
    }
}


</script>