<template>
    <section class="container container--bleed subscription-banner">
        <section class="container container--50-50">
            <div class="container__col container__col--50">
                <div class="annotation-wrapper annotation-wrapper--rich-text container js-annotation-wrapper">
                    <div class="annotation-wrapper__shrinkwrap">
                        <div class="rich-text ">
                            <h2 class="subscription-header">Stay in Touch</h2>
                            <p>Receive our quarterly newsletter and latest announcements.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="submitted" class="container__col container__col--50">

                <h2 class="global-modal__heading">
                    <div>Thank you
                        for signing up for our newsletter</div>
                </h2>

            </div>
            <div v-else class="container__col container__col--50">

                <Form novalidate @submit="onSubmit" :validation-schema="schema" :initial-values="formValues"
                    v-slot="{ values }">
                    <div class="global-modal__form-row">
                        <div class="global-modal__field-group global-modal__field-group--grow">

                            <label class="sc-form-item js-sc-form-required">
                                <span class="global-modal__label">First Name</span>
                                <Field name="name" type="text" class="global-modal__input" />
                                <ErrorMessage class="field-validation-error" name="name" />
                            </label>
                        </div>
                        <div class="global-modal__field-group global-modal__field-group--grow">
                            <label class="sc-form-item js-sc-form-required">
                                <span class="global-modal__label">Last Name</span>
                                <Field name="surname" type="text" class="global-modal__input" />
                                <ErrorMessage class="field-validation-error" name="surname" />
                            </label>
                        </div>
                    </div>
                    <div class="global-modal__form-row">
                        <div class="global-modal__field-group global-modal__field-group--grow">
                            <label class="sc-form-item js-sc-form-required">
                                <span class="global-modal__label">Email Address</span>
                                <Field name="email" type="text" class="global-modal__input" />
                                <ErrorMessage class="field-validation-error" name="email" />
                                <label class="sc-form-item__label sc-form-item__label--checkbox">I am interested
                                    in...</label>
                                <br>
                                <ErrorMessage class="field-validation-error" name="interestedTopics" />
                            </label>
                        </div>
                    </div>
                    <div>
                        <div class="page-form__checkbox-column-container" v-for="topic in interestedTopics">
                            <label class="checkbox">
                                <Field name="interestedTopics" type="checkbox" :value="topic.value" />
                                <span class="checkbox__custom">
                                </span>
                                {{ topic.text }}
                            </label>
                        </div>
                    </div>
                    <div class="sc-form__button-row">
                        <button class="global-modal__submit btn btn--primary">Submit</button>
                    </div>
                </Form>
            </div>
        </section>
    </section>
</template>

<script setup lang="ts">
import type { MailDataRequired } from '@sendgrid/mail';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { contentTypes } from '~/models';

const siteStore = useSiteStore();

const interestedTopics = siteStore.interestedTopics.map((topic) => {
    return {
        text: topic.elements.title.value,
        value: topic.elements.crmField.value,
    };
});
const formValues = {
    name: '',
    surname: '',
    email: '',
    interestedTopics: [...interestedTopics.map((topic) => topic.value)],
};

function getText(event: any) {
    let text = `<p>Here's the confirmation data about news letter signin from the ADDI website: </p>
    <p><b>From:</b> ${event.name} ${event.surname}</p>
    <p><b>Email:</b> ${event.email}</p>
    <ul>`
    for (let i = 0; i < event.interestedTopics.length; i++) {
        text += `<li>${interestedTopics.find(x => x.value == event.interestedTopics[i])?.text}</li>`
    }
    text += `</ul>`
    return text
}


const submitted = ref(false)

const schema = yup.object({
    name: yup.string().required("You must provide your name"),
    surname: yup.string().required("You must provide your second name"),
    email: yup.string().required("You must provide a valid email"),
    interestedTopics: yup.array().min(1, "You must select at least one topic")
});

const onSubmit = async (values: any) => {

    const success = await sendEmail({
        subject: `ADDI News Letter - ${values.name} ${values.surname}`,
        html: getText(values)
    } as MailDataRequired)

    const crmResult = await subscribeToNewsletter(values)

    if (success && crmResult) {
        submitted.value = true;
    }
};

</script>

<style lang="scss" scoped>
.subscription-banner {
    padding: 1rem;
    background-color: #02b7cf;

    h2,
    p {
        color: #ffffff;
    }
}

.rich-text a {
    border-bottom: none;
}

.rich-text .subscription-header {
    font-size: 48px;
}

.sc-form-item {
    margin-top: 0px;
}

.global-modal__submit {
    margin-top: 0px;
}

.global-modal__heading+.global-modal__form-row {
    margin-top: 0px;
}

.global-modal__heading {
    margin-top: 0px;
}

.global-modal__form-row+.global-modal__form-row {
    margin-top: 20px;
}
</style>