<template>
    <component v-if="activeComponent && !templateClass" :is="activeComponent" :model="model" :options="options" />
</template>

<script lang="ts" setup>


import type { IContentItem, IContentItemElements, IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import type { PortableTextComponentProps } from '@portabletext/vue';
import {
    AdvisoryBoardListing, Block5050, ContentBanner, NewsList, VideoItem, SubscriptionBanner, FellowsList,
    TeamBiosListing, TeamListing, TextBanner, CallToAction, PageBannerLink, PageBannerLinkItem, HomePartners, BannerQuote,
    AccordionList, CaseStudyQuote, Block5050Video, Tile, RichTextTable, AnchorTag, TeamMemberItem, BoardMemberItem, TechnicalMemberItem, ContactUsForm
} from '#components'
import type {
    BannerContentModel, BannerTitleTextModel, N5050BlockModel, TeamListModel, TeamMemberListModel, VideoModel,
    FellowsListModel, CallToActionModel, BannerLinksModel, TileModel, BannerPartnersModel, BannerQuoteModel, BannerLinkModel,
    AccordionListModel, CaseStudyQuoteModel, N5050VideoBlockModel, AnchorTagModel, RichTextTableModel, TeamMemberModel,
    AdvisoryBoardMemberModel, GlobalTechnicalAdvisoryCommitteeMemberModel, ContactUsFormModel
} from '~/models/content-types';
import { contentTypes } from '~/models';

//#region Get Options Obj by type.

const optionsTypeMap = new Map<any, object>([
    [HomePartners, {
        rowLimit: 7
    }],

]);


//#endregion

//#region  Content Type - GetComponent Map
const contentTypeMap = new Map<string, Function>([
    [contentTypes.n50_50_block.codename, (item: IContentItem<IContentItemElements>) => _getComponent<N5050BlockModel>(item, Block5050)],
    [contentTypes.team_list.codename, (item: IContentItem<IContentItemElements>) => _getComponent<TeamListModel>(item, TeamListing)],
    [contentTypes.team_member_list.codename, (item: IContentItem<IContentItemElements>) => _getComponent<TeamMemberListModel>(item, TeamBiosListing)],
    [contentTypes.advisory_board_member_list.codename, (item: IContentItem<IContentItemElements>) => _getComponent<TeamMemberListModel>(item, AdvisoryBoardListing)],
    [contentTypes.banner___content.codename, (item: IContentItem<IContentItemElements>) => _getComponent<BannerContentModel>(item, ContentBanner)],
    [contentTypes.banner___title___text.codename, (item: IContentItem<IContentItemElements>) => _getComponent<BannerTitleTextModel>(item, TextBanner)],
    [contentTypes.newsletter_subscription.codename, (item: IContentItem<IContentItemElements>) => _getComponent<{}>(item, SubscriptionBanner)],
    [contentTypes.news_list.codename, (item: IContentItem<IContentItemElements>) => _getComponent<{}>(item, NewsList)],
    [contentTypes.video.codename, (item: IContentItem<IContentItemElements>) => _getComponent<VideoModel>(item, VideoItem)],
    [contentTypes.fellows_list.codename, (item: IContentItem<IContentItemElements>) => _getComponent<FellowsListModel>(item, FellowsList)],
    [contentTypes.call_to_action.codename, (item: IContentItem<IContentItemElements>) => _getComponent<CallToActionModel>(item, CallToAction)],
    [contentTypes.banner___quote.codename, (item: IContentItem<IContentItemElements>) => _getComponent<BannerQuoteModel>(item, BannerQuote)],
    [contentTypes.banner___links.codename, (item: IContentItem<IContentItemElements>) => _getComponent<BannerLinksModel>(item, PageBannerLink)],
    [contentTypes.banner___link.codename, (item: IContentItem<IContentItemElements>) => _getComponent<BannerLinkModel>(item, PageBannerLinkItem)],
    [contentTypes.banner___partners.codename, (item: IContentItem<IContentItemElements>) => _getComponent<BannerPartnersModel>(item, HomePartners)],
    [contentTypes.accordion_list.codename, (item: IContentItem<IContentItemElements>) => _getComponent<AccordionListModel>(item, AccordionList)],
    [contentTypes.case_study___quote.codename, (item: IContentItem<IContentItemElements>) => _getComponent<CaseStudyQuoteModel>(item, CaseStudyQuote)],
    [contentTypes.n50_50_video_block.codename, (item: IContentItem<IContentItemElements>) => _getComponent<N5050VideoBlockModel>(item, Block5050Video)],
    [contentTypes.rich_text___table.codename, (item: IContentItem<IContentItemElements>) => _getComponent<RichTextTableModel>(item, RichTextTable)],
    [contentTypes.anchor_tag.codename, (item: IContentItem<IContentItemElements>) => _getComponent<AnchorTagModel>(item, AnchorTag)],
    [contentTypes.team_member.codename, (item: IContentItem<IContentItemElements>) => _getComponent<TeamMemberModel>(item, TeamMemberItem)],
    [contentTypes.advisory_board_member.codename, (item: IContentItem<IContentItemElements>) => _getComponent<AdvisoryBoardMemberModel>(item, BoardMemberItem)],
    [contentTypes._global_technical_advisory_committee_member.codename, (item: IContentItem<IContentItemElements>) => _getComponent<GlobalTechnicalAdvisoryCommitteeMemberModel>(item, TechnicalMemberItem)],
    [contentTypes.contact_us_form.codename, (item: IContentItem<IContentItemElements>) => _getComponent<ContactUsFormModel>(item, ContactUsForm)]
]);

function _getComponent<Model>(item: IContentItem<IContentItemElements>, component: any) {

    model.value = item as Model;
    if (optionsTypeMap.has(component)) {
        options.value = optionsTypeMap.get(component);
    }

    return component;
}
//#endregion

const props = defineProps<
    PortableTextComponentProps<{
        component: any
    }>
>();


let activeComponent: any = null
const model = ref<any>();
const options = ref<any>();
const templateClass = ref<string>();


let linkedItem: any = null
const pageStore = usePageStore()
const linkedItems = pageStore.linkedItems as IContentItemsContainer
if (props.value.component._type === 'reference') {
    const codename = props.value.component._ref

    linkedItem = linkedItems[codename]
    if (linkedItem) {
        activeComponent = getComponent(linkedItem)
    }
}

function getComponent(item: IContentItem<IContentItemElements>) {

    try {
        if (!item?.system?.type) {
            console.log("type not provided");
            return null;
        }
        else {
            if (contentTypeMap.has(item.system.type)) {
                const comp = contentTypeMap.get(item.system.type);
                if (comp) {
                    return comp(item);
                }
            }
            else {
                console.error(`Missing Map: ${item.system.type}`);
            }
        }
    }
    catch (error) {
        console.log('error', error)
    }


    return null
}




</script>
