<template>
    <div class="advisory-board" v-bind:[attributes.componentId]="type?.id">
        <div class="rich-text">
            <table>
                <tbody>
                    <tr v-for="member in memberList" :key="member.system.codename" :id="member.system.codename"
                        v-bind:[attributes.itemId]="member?.system.id">
                        <td>
                            <h3>{{ member.elements.titleTextTitle.value }}</h3>
                            <RichText :value="member.elements.titleTextRichText.value" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts" setup>

import type { AdvisoryBoardMemberListModel, AdvisoryBoardMemberModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.advisory_board_member_list;
const itemType = contentTypes.advisory_board_member.elements;
const pageStore = usePageStore()
const props = defineProps<{ model: AdvisoryBoardMemberListModel }>();
const teamMembers = props.model.elements.memberList;
const memberList = ref<AdvisoryBoardMemberModel[]>([]);
memberList.value = [...teamMembers.value.map(item => pageStore.getLinkedItem<AdvisoryBoardMemberModel>(item))];

</script>

<style lang="scss" scoped></style>