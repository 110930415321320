<template>
    <Head>
        <Title>{{ title }}</Title>
        <Meta property="og:url" :content="url" />
        <Meta property="og:type" content="website" />
        <Meta property="og:locale" content="en_US" />
        <Meta property="og:site_name" content="ADDI" />
        <Meta name="twitter:card" content="summary_large_image" />
        <Meta name="title" :content="title" />
        <Meta property="og:title" :content="title" />
        <Meta name="twitter:title" :content="title" />
        <Meta name="description" :content="description" />
        <Meta property="og:description" :content="description" />
        <Meta name="twitter:description" :content="description" />
        <Meta property="og:image" :content="image" />
        <Meta name="twitter:image" :content="image" />
        <Meta name="twitter:site" :content="creator" />
        <Meta name="twitter:creator" :content="creator" />
        <Meta v-if="!isProduction" name="robots" content="noindex, nofollow" />
    </Head>
</template>
<script lang="ts" setup>
import type { CaseStudyModel, DataSharingToolkitModel, HeroModel, HomepageModel, NewsItemModel, NewsLetterPageModel, NewsletterItemModel, PageModel } from '~/models';
import { contentTypes } from '~/models/project/contentTypes';
import { truncateWords, stripHtmlTags } from '~/utils/string-helper';
const config = useRuntimeConfig()
const route = useRoute()
const pageStore = usePageStore()
const isProduction = config.public.isProduction
const creator = "@AlzData"
const title = ref<string>("")
const url = ref<string>("")
const description = ref<string>("")
const image = ref<string>("")
if (pageStore.page) {
    title.value = stripHtmlTags(pageStore.pageTitle)
}
else {
    title.value = "Not Found - ADDI"
}

url.value = `${config.public.appHost}${route.path}`
if (pageStore.page) {
    switch (pageStore.page.system.type) {
        case contentTypes.homepage.codename: {
            const model = pageStore.page as HomepageModel
            description.value = model.elements.seoMetadataMetaDescriptions.value
            break
        }
        case contentTypes.page.codename: {
            const model = pageStore.page as PageModel
            description.value = model.elements.seoMetadataMetaDescriptions.value
            if (model.elements.hero.value.length > 0) {
                const pageHero = pageStore.getLinkedItem<HeroModel>(model.elements.hero.value[0])
                image.value = pageHero.elements.image.value.length > 0 ? pageHero.elements.image.value[0].url : ''
            }
            break
        }
        case contentTypes.data_sharing_toolkit.codename: {
            const model = pageStore.page as DataSharingToolkitModel
            description.value = model.elements.seoMetadataMetaDescriptions.value.length > 0
                ? model.elements.seoMetadataMetaDescriptions.value
                : truncateWords(model.elements.subTitle.value, 160)

            image.value = model.elements.image.value.length > 0 ? model.elements.image.value[0].url : ''
            break
        }
        case contentTypes.news_item.codename: {
            const model = pageStore.page as NewsItemModel
            description.value = model.elements.seoMetadataMetaDescriptions.value.length > 0
                ? model.elements.seoMetadataMetaDescriptions.value
                : truncateWords(model.elements.subTitle.value, 160)

            image.value = model.elements.image.value.length > 0 ? model.elements.image.value[0].url : ''
            break
        }
        case contentTypes.news_letter_page.codename: {
            const model = pageStore.page as NewsLetterPageModel
            description.value = model.elements.seoMetadataMetaDescriptions.value.length > 0
                ? model.elements.seoMetadataMetaDescriptions.value
                : truncateWords(model.elements.subTitle.value, 160)

            break
        }
        case contentTypes._newsletter_item.codename: {
            const model = pageStore.page as NewsletterItemModel
            description.value = model.elements.seoMetadataMetaDescriptions.value
            image.value = model.elements.image.value.length > 0 ? model.elements.image.value[0].url : ''

            break
        }
        case contentTypes.case_study.codename: {
            const model = pageStore.page as CaseStudyModel
            description.value = model.elements.seoMetadataMetaDescriptions.value
            image.value = model.elements.image.value.length > 0 ? model.elements.image.value[0].url : ''
            break
        }
        default:
    }
}

</script>