<template>
    <section class="container container--70-30 case-study-item" v-bind:[attributes.itemId]="data?.system.id">
        <div class="container__col container__col--70">
            <div class="back-link">
                <NuxtLink to="/case-studies" class="back-link__link">
                    Back to
                    Data Sharing Case Studies - {{ siteStore.site.elements.siteShortName.value }} </NuxtLink>
            </div>
            <div class="page-header container">
                <div class="page-header__eyebrow">Case Study</div>
                <h1 class="page-header__title" v-bind:[attributes.elementCodename]="type.case_study_title.codename">{{
                    data?.elements?.caseStudyTitle?.value }}</h1>
                <div class="page-header__subtitle" v-bind:[attributes.elementCodename]="type.sub_title.codename">{{
                    data?.elements?.subTitle?.value }}</div>
            </div>
            <div class="image image--break-container">
                <figure class="image__media" v-bind:[attributes.elementCodename]="type.image.codename">
                    <NuxtImg provider="kontentAiProvider" :src="data?.elements?.image?.value[0]?.url" />
                    <figcaption class="image__caption">
                    </figcaption>
                </figure>
            </div>

            <div class="summary container" v-if="data?.elements?.introContent?.value"
                v-bind:[attributes.elementCodename]="type.intro_content.codename">
                <div class="summary__text">
                    <div class="rich-text">
                        <RichText :value="data?.elements?.introContent?.value"></RichText>
                    </div>
                </div>
            </div>
            <div class="rich-text" v-bind:[attributes.elementCodename]="type.content.codename">
                <RichText :value="data?.elements?.content?.value" v-if="data?.elements?.content?.value"></RichText>
            </div>
            <hr>
            <div class="rich-text footnote" v-bind:[attributes.elementCodename]="type.footer.codename">
                <RichText :value="data?.elements?.footer?.value"></RichText>
            </div>
        </div>
        <div class="container__col container__col--30"></div>
    </section>
</template>
<script lang="ts" setup>
import type { CaseStudyModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.case_study.elements;
const siteStore = useSiteStore()
const page = usePageStore();

const data = page.page as CaseStudyModel
</script>

<style lang="scss" scoped>
@use "~/assets/scss/page-header.scss";
@use "~/assets/scss/image.scss";
@use "~/assets/scss/summary.scss";
</style>