<template>
    <NuxtLink :to="url" class="horizontal-card__link" v-bind:[attributes.componentId]="typeId">
        <div class="horizontal-card__card" :style="getStyle(model)"
            v-bind:[attributes.elementCodename]="type.title.codename">
            <div class="horizontal-card__copy">
                <div class="horizontal-card__text">
                    {{ model?.elements?.title?.value }}
                </div>
            </div>
        </div>
    </NuxtLink>
</template>
<script lang="ts" setup>

import type { TileModel } from '~/models';
const siteStore = useSiteStore()
const props = defineProps<{ model: TileModel }>();
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.tile.elements;
const typeId = contentTypes.tile.id;
const url = ref<string>('');
url.value = getRefByModel(props.model);

function getRefByModel(model: TileModel) {
    if (model?.elements?.itemLink?.value.length > 0) {
        const codename = model?.elements?.itemLink?.value[0]
        //console.log(codename)
        return siteStore.getUrlByCodename(codename)
    }
    else if (model?.elements?.externalLink?.value.length > 0) {
        return model?.elements?.externalLink.value
    }

    return "/";
}

function getStyle(model: TileModel) {
    const url = model?.elements?.backgroundImage?.value[0]?.url ?? '/';
    return `background-image: url(${url}?auto=format)`;
}

</script>
<style lang="scss" scoped>
@use "~/assets/scss/horizontal_card.scss";
@use "~/assets/scss/a.scss";
</style>