<template>
    <section v-if="fullWidth" class="container container--bleed banner-quote" v-bind:[attributes.componentId]="type?.id">
        <div class="container container--100">
            <div class="rich-text">
                <span v-html="quoteText" v-bind:[attributes.elementCodename]="type.elements.quote.codename"></span>
                <ul style="margin-bottom: 20px;" v-bind:[attributes.elementCodename]="type.elements.quote_author.codename">
                    <li>{{ quoteAuthor }} </li>
                </ul>
            </div>
        </div>
    </section>
    <section v-else class="container container--bleed banner-quote" v-bind:[attributes.itemId]="model?.system.id">
        <div class="container container--50-50">
            <div class="container__col container__col--50">
                <div class="quote-banner__left">
                    <div class="quote-banner__title" v-bind:[attributes.elementCodename]="type.elements.title.codename">{{ title }}</div>

                    <div class="quote-banner__date" v-bind:[attributes.elementCodename]="type.elements.sub_title.codename">{{ subTitle }}</div>
                </div>
            </div>
            <div class="container__col container__col--50">
                <div class="quote-banner__right">
                    <svg class="quote-mark" ariahidden="true" xmlns="http://www.w3.org/2000/svg" width="51" height="46"
                        viewBox="0 0 51 46">
                        <title>Quote Mark</title>
                        <text fill-rule="evenodd" font-size="200" transform="translate(-8 -53)">
                            <tspan x="0" y="194">“</tspan>
                        </text>
                    </svg>
                    <blockquote class="quote-banner__quote">
                        <span v-html="quoteText" v-bind:[attributes.elementCodename]="type.elements.quote.codename"></span>
                        <div class="quote-banner__caption">
                            <span class="quote-banner__author" v-bind:[attributes.elementCodename]="type.elements.quote_author.codename">{{ quoteAuthor }}</span>
                            <!-- <NuxtLink class="btn btn--primary btn--black-hover" rel="noopener noreferrer"
                                    v-for="(cta) in model.elements.cta.linkedItems" :href="cta.elements.manualTarget.value"
                                    target="_blank">{{ cta.elements.title.value }}</NuxtLink> -->
                            <template v-if="model?.elements.cta.value.length > 0">
                                <CallToAction v-for="(cta) in model?.elements.cta.value"
                                    :model="pageStore.getLinkedItem<CallToActionModel>(cta)" />
                            </template>
                        </div>
                    </blockquote>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
import type { BannerQuoteModel, CallToActionModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.banner___quote;
const props = defineProps<{ model: BannerQuoteModel }>();
const pageStore = usePageStore()
const fullWidth = props.model.elements.style.value[0].codename == 'full_width' ? true : false;
const quoteText = props.model.elements.quote.value;
const title = props.model.elements.title.value;
const subTitle = props.model.elements.subTitle.value;
const quoteAuthor = props.model.elements.quoteAuthor.value;
</script>
<style lang="scss" scoped>
@use "~/assets/scss/quote-banner.scss";
</style>