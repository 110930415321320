<template>
    <section class="container container--70-30" v-bind:[attributes.componentId]="type?.id">
        <div class="container__col container__col--70">
            <div class="back-link">
                <NuxtLink to="/" class="back-link__link">
                    Back to
                    {{ siteStore.site.elements.siteName.value }} ({{ siteStore.site.elements.siteShortName.value }})
                </NuxtLink>
            </div>
            <div class="rich-text">
                <div v-bind:[attributes.elementCodename]="type.elements.title.codename" v-html="data.elements.title.value"></div>
                <div v-bind:[attributes.elementCodename]="type.elements.sub_title.codename" v-html="data.elements.subTitle.value">
                </div>
            </div>

        </div>
        <div class="container__col container__col--30">

        </div>


    </section>
    <section class="container container--bleed" v-bind:[attributes.elementCodename]="type.elements.case_study_items.codename">
        <CaseStudyItem v-for="(item) in data.elements.caseStudyItems.value"
            :model="pageStore.getLinkedItem<CaseStudyModel>(item)"></CaseStudyItem>
    </section>
</template>
<script setup lang="ts">
import type { CaseStudyListModel, CaseStudyModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.case_study_list;
const siteStore = useSiteStore()
const pageStore = usePageStore()

const data = pageStore.page as CaseStudyListModel

//console.log(data);
</script>