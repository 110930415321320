<template>
    <div class="interior-page" :class="data.system.codename">
        <PageHero :model="pageStore.getLinkedItem<HeroModel>(data.elements.hero.value[0])"
            v-if="data?.elements.hero.value.length > 0">
        </PageHero>
        <section class="container container--100">
            <VideoItem v-for="value in data.elements.video.value" :model="pageStore.getLinkedItem<VideoModel>(value)" />
        </section>
        <section class="container container--100">
            <Block5050 v-for="value in data.elements.app5050Blocks.value"
                :model="pageStore.getLinkedItem<N5050BlockModel>(value)" />
        </section>
        <section class="container container--100">
            <h2 class="text-cerulean">{{ data.elements.collaborationTitle.value }}</h2>
            <Block5050 v-for="value in data.elements.collaboration5050Blocks.value"
                :model="pageStore.getLinkedItem<N5050BlockModel>(value)" />
        </section>
        <section class="container container--bleed dark-style">
            <div class="container container--100">
                <BlackInfoBlock v-for="value in data.elements.blackInfoBlock.value"
                :model="pageStore.getLinkedItem<BlackInfoBlockModel>(value)" />
            </div>
        </section>
        <section class="container container--100">
            <h2 class="text-cerulean">{{ data.elements.tutorialsTitle.value }}</h2>
            <Block5050Video v-for="value in data.elements.video5050VideoBlocks.value"
                :model="pageStore.getLinkedItem<N5050VideoBlockModel>(value)" />
        </section>
        <section class="container container--bleed">
            <ContentBanner v-for="value in data.elements.rocheTrademarks.value"
                :model="pageStore.getLinkedItem<BannerContentModel>(value)" />
        </section>
        <section class="container container--bleed">
            <HomePartners v-for="value in data.elements.partnersBanner.value"
                :model="pageStore.getLinkedItem<BannerPartnersModel>(value)" :options="{ rowLimit: 5 }" />
        </section>
    </div>
</template>
<script setup lang="ts">

import type { VideoModel, HeroModel, HomepageModel, NtkAppPageModel, PageModel, N5050BlockModel, BlackInfoBlockModel, N5050VideoBlockModel, BannerContentModel, BannerPartnersModel } from '~/models/content-types';

interface BreadCrumb {
    title: string;
    url: string;
}
const pageStore = usePageStore()
const data = pageStore.page as NtkAppPageModel

</script>

<style lang="scss" scoped>
@use "~/assets/scss/ntk-app-page.scss";
</style>
