<template>
    <section class="container container--100 " v-bind:[attributes.componentId]="typeId">
        <section class="video-block">
            <h2 class="component-title" v-bind:[attributes.elementCodename]="type.title.codename">{{ model.elements.title.value}}</h2>
            <span v-bind:[attributes.elementCodename]="type.video_embed_code.codename" v-html="model.elements.videoEmbedCode.value"></span>
            <span v-bind:[attributes.elementCodename]="type.content.codename" v-html="model.elements.content.value"></span>
        </section>
    </section>
</template>
<script lang="ts" setup>
import type { VideoModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.video.elements;
const typeId = contentTypes.news_item.id;

const props = defineProps<{model: VideoModel}>();

</script>

<style lang="scss" scoped>

</style>
