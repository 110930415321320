<template>
    <div class="interior-page" :class="data.system.codename" v-bind:[attributes.itemId]="data?.system.id">
        <PageHero :model="pageStore.getLinkedItem<HeroModel>(data.elements.hero.value[0])"
            v-if="data?.elements.hero.value.length > 0">
        </PageHero>
        <section :class="sectionClassName">
            <div class="back-link" v-if="breadCrumb && breadCrumb.title && breadCrumb.url">
                <NuxtLink :to="breadCrumb.url" class="back-link__link">
                    Back to {{ breadCrumb.title }}
                </NuxtLink>
            </div>
            <RichText :value="data.elements.content?.value" />
        </section>
    </div>
</template>
<script setup lang="ts">

import type { HeroModel, HomepageModel, PageModel } from '~/models/content-types';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.page.elements;

interface BreadCrumb {
    title: string;
    url: string;
}
const pageStore = usePageStore()
const siteStore = useSiteStore()
const data = pageStore.page as PageModel
const sectionClassName = ref<string>('')
const breadCrumb = reactive<BreadCrumb>({} as BreadCrumb)
if (data.elements.breadcrumbTarget.value.length > 0) {
    const breadcrumbTarget = pageStore.getLinkedItem<PageModel | HomepageModel>(data.elements.breadcrumbTarget.value[0])
    //console.log('breadcrumbTarget', breadcrumbTarget)
    if (breadcrumbTarget) {
        breadCrumb.title = breadcrumbTarget.elements.title.value
        breadCrumb.url = siteStore.getUrlByCodename(breadcrumbTarget.system.codename)
    }

}

//console.log(data);

if (data.elements.sectionStyle.value.length > 0) {
    const codename = data.elements.sectionStyle.value[0].codename;

    if (codename === 'full_bleed') {
        sectionClassName.value = 'container container--bleed';
    } else if (codename === 'narrow_container') {
        sectionClassName.value = 'container container--narrow';
    } else {
        sectionClassName.value = 'container container--100';
    }
}
else {
    sectionClassName.value = 'container container--100'
}

</script>