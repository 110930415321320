<template>
    <section class="container container--bleed">
        <div class="tools-header">
            <div class="container container--100 tools-header__content-container"
                v-bind:[attributes.itemId]="data?.system.id">
                <div class="tools-header__image">
                    <NuxtImg provider="kontentAiProvider" v-if="data.elements.image.value.length > 0"
                        :src="data.elements.image.value[0].url" :height="`${data.elements.image.value[0].height}px`"
                        :width="`${data.elements.image.value[0].width}px`"></NuxtImg>
                </div>
                <div class="tools-header__text">
                    <h1 class="tools-header__heading" v-bind:[attributes.elementCodename]="type.title.codename">{{
                        data.elements.title.value }}</h1>
                    <div class="tools-header__description" v-bind:[attributes.elementCodename]="type.sub_title.codename">
                        <span v-html="data.elements.subTitle.value"></span>
                    </div>
                    <div class="tools-header__image tools-header__image--mobile"
                        v-bind:[attributes.elementCodename]="type.image.codename">
                        <NuxtImg provider="kontentAiProvider" v-if="data.elements.image.value.length > 0"
                            :src="data.elements.image.value[0].url" :height="`${data.elements.image.value[0].height}px`"
                            :width="`${data.elements.image.value[0].width}px`"></NuxtImg>
                    </div>
                    <div class="tools-header__gradient">
                        <div class="tools-header__gradient-content">
                            <div class="tools-header__sub-text">
                                <h2 class="tools-header__sub-title"
                                    v-bind:[attributes.elementCodename]="type.banner_title.codename">{{
                                        data.elements.bannerTitle.value }}</h2>
                                <div class="tools-header__sub-description"
                                    v-bind:[attributes.elementCodename]="type.banner_content.codename">
                                    {{ data.elements.bannerContent.value }}
                                </div>
                                <CallToAction :model="cta" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="container container--narrow" v-bind:[attributes.elementCodename]="type.accordion_content.codename">
        <AccordionList :model="accordionList" />
    </section>
    <section class="container container--narrow" v-bind:[attributes.elementCodename]="type.disclaimer.codename">
        <div class="rich-text">
            <div class="footnote">
                {{ data.elements.disclaimer.value }}
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
import { type DataSharingToolkitModel, type CallToActionModel, type AccordionListModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.data_sharing_toolkit.elements;
const pageStore = usePageStore()
const data = pageStore.page as DataSharingToolkitModel

let cta = reactive<CallToActionModel>({} as CallToActionModel)
if (data.elements.callToAction.linkedItemCodenames.length > 0) {
    cta = pageStore.getLinkedItem<CallToActionModel>(data.elements.callToAction.linkedItemCodenames[0])
}

let accordionList = reactive<AccordionListModel>({} as AccordionListModel)
if (data.elements.accordionContent.linkedItemCodenames.length > 0) {
    accordionList = pageStore.getLinkedItem<AccordionListModel>(data.elements.accordionContent.linkedItemCodenames[0])
}

</script>
<style lang="scss" scoped>
@use "~/assets/scss/tools-header.scss";
@use "~/assets/scss/footnote.scss";
@use "~/assets/scss/rich-text.scss";
</style>
