<template>
    <div v-if="submitted" class="page-form container container--bleed" v-bind:[attributes.itemId]="page?.system.id">
        <div class="container container--100">
            <h1 class="page-form__header">
                <div id="submit-data-thank-you">Thank you for applying to submit data. We will be in touch with
                    you shortly!</div>
            </h1>
        </div>

    </div>
    <Form novalidate @submit="onSubmit" :validation-schema="schema" class="sc-form sc-form--with-header page-form__form"
        v-if="!submitted" v-bind:[attributes.itemId]="page?.system.id">

        <div class="page-form container container--bleed">
            <div class=" container container--100">
                <h1 class="page-form__header">{{ page?.elements?.title?.value }}</h1>
                <p class="page-form__subtitle">{{ page?.elements?.subtitle?.value }}</p>

                <div class="page-form__row">
                    <div class="page-form__field-group">

                        <label class="sc-form-item js-sc-form-required">
                            <span class="page-form__label">First Name</span>
                            <Field name="name" type="text" class="page-form__input" placeholder="Enter First Name" />
                            <ErrorMessage class="field-validation-error" name="name" />
                        </label>

                    </div>

                    <div class="page-form__field-group">

                        <label class="sc-form-item js-sc-form-required">
                            <span class="page-form__label">Last Name</span>
                            <Field name="surname" type="text" class="page-form__input" placeholder="Enter Last Name" />
                            <ErrorMessage class="field-validation-error" name="surname" />
                        </label>

                    </div>

                    <div class="page-form__field-group">

                        <label class="sc-form-item js-sc-form-required">
                            <span class="page-form__label">Email Address</span>
                            <Field name="email" type="text" class="page-form__input" placeholder="Enter Email Address" />
                            <ErrorMessage class="field-validation-error" name="email" />
                        </label>

                    </div>
                </div>

                <div class="page-form__row">
                    <div class="page-form__field-group">

                        <label class="sc-form-item js-sc-form-required">
                            <span class="page-form__label">Job Title</span>
                            <Field name="jobTitle" type="text" class="page-form__input" placeholder="Enter Job Title" />
                            <ErrorMessage class="field-validation-error" name="jobTitle" />
                        </label>

                    </div>

                    <div class="page-form__field-group page-form__field-group--grow ">

                        <label class="sc-form-item js-sc-form-required">
                            <span class="page-form__label">Organization Name</span>
                            <Field name="organizationalName" type="text" class="page-form__input"
                                placeholder="Enter Organization Name" />
                            <ErrorMessage class="field-validation-error" name="organizationalName" />
                        </label>

                    </div>


                </div>

                <div class="page-form__row">
                    <div class="page-form__field-group">

                        <label class="sc-form-item js-sc-form-required">
                            <span class="page-form__label">Organization URL</span>
                            <Field name="organizationalURL" type="text" class="page-form__input"
                                placeholder="Enter Organization URL" />
                            <ErrorMessage class="field-validation-error" name="organizationalURL" />
                        </label>

                    </div>

                    <div class="page-form__field-group page-form__field-group--grow">

                        <label class="sc-form-item js-sc-form-required">
                            <span class="page-form__label">Organization Country</span>
                            <Field name="organizationalCountry" type="text" class="page-form__input"
                                placeholder="Enter Organization Country" />
                            <ErrorMessage class="field-validation-error" name="organizationalCountry" />
                        </label>

                    </div>
                </div>

                <div class="page-form__row">
                    <div class="page-form__field-group page-form__field-group--grow">
                        <div class="page-form__checkbox-column-container">
                            <div class="page-form__checkbox-column">
                                <label class="page-form__label">Areas of Expertise</label>
                                <label class="checkbox">
                                    <Field name="area" type="checkbox" value="381200000" />
                                    <span class="checkbox__custom">
                                    </span>
                                    Data Science
                                </label>
                                <label class="checkbox">
                                    <Field name="area" type="checkbox" value="381200001" />
                                    <span class="checkbox__custom">
                                    </span>
                                    Alzheimer's Disease
                                </label>
                                <label class="checkbox">
                                    <Field name="area" type="checkbox" value="381200002" />
                                    <span class="checkbox__custom">
                                    </span>
                                    Dementia
                                </label>
                                <ErrorMessage class="field-validation-error" name="area" />
                            </div>
                        </div>
                    </div>

                    <div class="page-form__field-group page-form__field-group--grow">
                        <label class="page-form__label">How did you hear about ADDI?</label>
                        <Field class="page-form__input page-form__select input-validation-error" name="howDidYouHear"
                            as="select" @update:model-value="onChange">
                            <option label=" "></option>
                            <option value="381200000">Conference</option>
                            <option value="381200001">Colleagues</option>
                            <option value="381200002">Social Media</option>
                            <option value="381200003">Online search</option>
                            <option value="381200004">Other</option>
                        </Field>
                        <ErrorMessage class="field-validation-error" name="howDidYouHear" />
                    </div>
                </div>

                <div class="page-form__row" v-if="showOtherTextBox">
                    <div class="page-form__field-group page-form__field-group--textarea">
                        <label class="sc-form-item sc-form-item--optional">
                            <span class="page-form__label">How did you hear about ADDI?</span>
                            <Field class="page-form__input page-form__textarea" name="other" as="textarea"
                                placeholder="Enter Comments"></Field>
                            <ErrorMessage class="field-validation-error" name="other" />
                        </label>
                    </div>
                </div>

                <div class="page-form__row">
                    <div class="page-form__field-group page-form__field-group--textarea">
                        <label class="sc-form-item sc-form-item--optional">
                            <span class="page-form__label">Comments</span>
                            <Field class="page-form__input page-form__textarea" name="comments" as="textarea"
                                placeholder="Enter Comments"></Field>
                            <ErrorMessage class="field-validation-error" name="comments" />
                        </label>
                    </div>
                </div>

                <div class="page-form__row page-form__row--buttons">
                    <div class="sc-form__button-row">
                        <button class="global-modal__submit btn btn--primary">Submit</button>
                    </div>
                </div>
            </div>
        </div>

    </Form>
</template>
<script lang="ts" setup>
import * as yup from 'yup';
import { Form, Field, ErrorMessage } from 'vee-validate';
import type { MailDataRequired } from "@sendgrid/mail";
import type { ApplyToSubmitDataModel } from '~/models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.apply_to_submit_data.elements;

const pageStore = usePageStore();

const showOtherTextBox = ref(false);

const page = pageStore.page as ApplyToSubmitDataModel

const submitted = ref(false);
const schema = yup.object({
    name: yup.string().required("First Name is a required field!"),
    surname: yup.string().required("Last Name is a required field!"),
    email: yup.string().required("Email Address is a required field!"),
    jobTitle: yup.string().required("Job Title is a required field!"),
    organizationalName: yup.string().required("Organization Name is a required field!"),
    organizationalURL: yup.string().required("Organization Url is a required field!"),
    organizationalCountry: yup.string().required("Organization Country is a required field!"),
    area: yup.array().required("Areas of Expertise is a required field!"),
    howDidYouHear: yup.string().required("How did you hear about ADDI? is a required field!"),
    other: yup.string(),
    comments: yup.string(),
    rememberMe: yup.bool(),
});

function onChange(args: any) {
    showOtherTextBox.value = args === '381200004';
    //console.log(showOtherTextBox.value);
}
function getText(event: any) {
    return `<p>Here's the latest data submission from the ADDI website: </p>
    <p><b>From:</b> ${event.name} ${event.surname}</p>
    <p><b>Email:</b> ${event.email}</p>
    <p><b>Job Title:</b> ${event.jobTitle}</p>
    <p><b>Organization Name:</b> ${event.organizationalName}</p>
    <p><b>Organization URL:</b> ${event.organizationalURL}</p>
    <p><b>Organization Country:</b> ${event.organizationalCountry}</p>        
    <p><b>Comments:</b> ${event.comments}</p>`;
}



function getAreas(input: string[]): string {
    let result = '';

    input.forEach(x => {
        result = `${result}, ${x}`
    });

    return result;
}

const onSubmit = async (event: any) => {

    try {
        const success = await sendEmail({
            to: event.email,
            subject: `ADDI Application to Submit Data - ${event.name} ${event.surname}`,
            html: getText(event)
        } as MailDataRequired)

        if (success) {
            submitted.value = true;
        }

        const formData = {
            name: event.name,
            surname: event.surname,
            email: event.email,
            jobTitle: event.jobTitle,
            organizationalName: event.organizationalName,
            organizationalURL: event.organizationalURL,
            organizationalCountry: event.organizationalCountry,
            area: event.area,
            howDidYouHear: event.howDidYouHear,
            howDidYouHearAboutAddiOther: event.other,
            comments: event.comments
        }
        const crmResult = await submitGrantApplication(formData);
        if (crmResult) {
            submitted.value = crmResult;
        }

    } catch (error) {
        console.error(error);
    }


};

</script>

<style lang="scss" scoped>
@use "~/assets/scss/page-form.scss";
</style>