<template>
    <div class="container container--100" v-bind:[attributes.componentId]="typeId">
        <div class="page-header">
            <div class="page-header__eyebrow"></div>
            <h1 class="title" v-bind:[attributes.elementCodename]="type.title.codename">{{ props.model.elements.title.value }}</h1>
            <span v-if="!isEmpty(props.model.elements.content.value)" class="subtitle" v-bind:[attributes.elementCodename]="type.content.codename" v-html="props.model.elements.content.value"></span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { isEmpty } from '~/utils/kontent-helper';
import type { BannerTitleTextModel } from '../models';
import { contentTypes } from '~/models';
const attributes = GetKontentAttributes();

const type = contentTypes.banner___title___text.elements;
const typeId = contentTypes.banner___title___text.id;
const props = defineProps<{ model: BannerTitleTextModel }>();


</script>

<style lang="scss" scoped>
@use "~/assets/scss/content-banner.scss";

.container {
    background-color: #fff;
    // padding-top: 10px;
    // padding-bottom: 50px;
    // margin-bottom: -30px;
    // padding-left: 30px;
    margin-top: 0px;
    text-align: left;
    font-size: 48px;
    font-weight: 300;
    // line-height: 70px;
    color: #000;
}

.title {
    font-size: 36px;
    font-weight: 400;
    line-height: 32px;

    @media (min-width: 680px) {
        font-size: 48px;
        font-weight: 300;
        line-height: 70px;
        margin-top: 20px;
    }
}

.subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 40px;
    display: block;

    @media (min-width: 680px) {
        font-size: 24px;
        font-weight: 400;
        line-height: 34px;
    }
}
</style>