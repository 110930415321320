import type { IContentItemsContainer } from '@kontent-ai/delivery-sdk'
import {
  type HomepageModel,
  type NewsItemModel,
  type PageModel,
  type DataSharingToolkitModel,
  contentTypes,
  type NewsLetterPageModel,
  type NewsletterItemModel,
  type CaseStudyListModel,
  type CaseStudyModel,
  type ApplyToSubmitDataModel,
  type NtkAppPageModel
} from '~/models'

interface PageState {
  page:
    | HomepageModel
    | PageModel
    | NewsItemModel
    | DataSharingToolkitModel
    | NewsLetterPageModel
    | NewsletterItemModel
    | CaseStudyListModel
    | CaseStudyModel
    | ApplyToSubmitDataModel
    | NtkAppPageModel
    | null
  linkedItems: IContentItemsContainer
}

export const usePageStore = defineStore('page', {
  state: (): PageState => ({
    page: null,
    linkedItems: {} as IContentItemsContainer
  }),
  actions: {
    async fetchData(itemId: string) {
      const response = await $fetch('/api/page/' + itemId)
      if (response) {
        //@ts-ignore
        this.page = getPageModel(response.data.item)
        //@ts-ignore
        this.linkedItems = response.data.linkedItems as IContentItemsContainer
        //   this.dataLoaded = true
      }

      function getPageModel(
        item: any
      ): HomepageModel | PageModel | NewsItemModel | DataSharingToolkitModel {
        switch (item.system.type) {
          case contentTypes.homepage.codename:
            return item as HomepageModel
          case contentTypes.page.codename:
            return item as PageModel
          case contentTypes.news_item.codename:
            return item as NewsItemModel
          case contentTypes.data_sharing_toolkit.codename:
            return item as DataSharingToolkitModel
          default:
            return item as HomepageModel | PageModel | NewsItemModel | DataSharingToolkitModel
        }
      }
    }
  },
  getters: {
    getLinkedItem:
      (state) =>
      <T>(codename: string) => {
        return state.linkedItems?.[codename] as T
      },
    pageTitle: (state) => {
      if (!state.page) return ''
      if (state.page.system.type === contentTypes.case_study.codename)
        //@ts-ignore
        return state.page.elements.landingPageTitle.value

      //@ts-ignore
      return state.page.elements.title.value
    }
  }
})
